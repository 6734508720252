import { Axios } from "../http/Axios";
export const permanentlyDeleteFile = async (file_id = ''): Promise<true> => {
    const fallback_error_message = 'Error occurred while deleting file';
    return new Promise((resolve, reject) => {
        Axios.delete(`admins/recycle-bin` + (file_id ? `/${file_id}` : ``))
            .then(async () => resolve(true))
            .catch((error) => {
                console.error(error);
                reject(error?.message || fallback_error_message)
            });
    })
};