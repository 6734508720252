import toast from "react-hot-toast";
import { Axios } from "../http/Axios";
import { Dispatch } from "@reduxjs/toolkit";
import { getUsersSuccess } from "../../pages/auth/authSlice";
import { UserInterface } from "../../interfaces/users.interface";

export const getUsers = async (dispatch: Dispatch) => {
    Axios.get(`users`)
        .then((response: { data: { data: Array<UserInterface> } }) => {
            dispatch(getUsersSuccess(response.data.data))
        })
        .catch((error) => {
            console.error(error);
            toast.error(error?.message || 'Error occurred while fetching users')
        });
};
