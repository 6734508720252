import React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface ConfirmationModalProps {
    show: boolean;
    message: string;
    onCancel: () => void;
    onConfirm: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    show,
    message,
    onConfirm,
    onCancel,
}) => {
    return (
        <Modal show={show} onHide={onCancel}>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={onConfirm}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal
