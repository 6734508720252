import storage from 'redux-persist/lib/storage';
import { authReducer } from './pages/auth/authSlice';
import { trashReducer } from './pages/trash/trashSlice';
import { filesReducer } from './pages/FolderContents/folderContentsSlice';
import { persistStore, persistReducer } from 'redux-persist';
import { starredReducer } from './pages/starred/starredSlice';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

const persistConfig = {
    key: 'auth',
    storage,
};

const persistedReducer = persistReducer(persistConfig, combineReducers({
    auth: authReducer,
    trash: trashReducer,
    files: filesReducer,
    starred: starredReducer,
}));

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>; // Add this line to export RootState type
export default store;
