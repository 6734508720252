import { useEffect, useState } from 'react';
import './PDFViewer.scss';
import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


interface PDFViewerProps {
    url: string;
}

const PDFViewer: React.FC<PDFViewerProps> = (props: PDFViewerProps) => {
    const { url } = props
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
        setNumPages(numPages);
    }

    const nextPage = () => {
        setPageNumber(pageNumber + 1);
    };

    const prevPage = () => {
        setPageNumber(pageNumber - 1);
    };

    useEffect(() => {
        setPageNumber(1);
    }, [url])

    return (
        <div className="pdf-container">
            <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
            </Document>
            <div className="d-flex">
                <div className='not-in-mobile'>
                    <p className='mt-3'>Page {pageNumber} of {numPages}</p>
                </div>
                <div className='ms-auto my-3'>
                    <button className='btn btn-sm btn-primary px-4 text-white shadow me-2' onClick={prevPage} disabled={pageNumber === 1}>
                        Previous Page
                    </button>
                    <button className='btn btn-sm btn-primary px-4 text-white shadow' onClick={nextPage} disabled={pageNumber === numPages}>
                        Next Page
                    </button>
                </div>
            </div>
            {/* <embed src={url} type="application/pdf" width="100%" height="600px" /> */}
        </div>
    );
};

export default PDFViewer