import config from '../../config';
import { UserAddressInterface, UserInterface } from '../../interfaces/users.interface';
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { FilesResponseInterface } from '../../interfaces/filesResponse.interface';
import { SessionInfoResponse } from '../../interfaces/sessionInfoResponse.interface';
import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoUserSession, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { CheckEmailResult } from '../../shared/enums/checkEmailResult.enum';
import { FolderInterface } from '../../interfaces/folder.interface';

interface AuthState {
    error: string | null;
    user: UserInterface | null;
    users: Array<UserInterface>;
    session: SessionInfoResponse | null;
    searches: {
        admin: string;
        folders: string;
        members: string;
    }
}

const initialState: AuthState = {
    users: [],
    user: null,
    error: null,
    session: null,
    searches: {
        admin: '',
        members: '',
        folders: ''
    }
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess: (state, action: PayloadAction<UserInterface>) => {
            state.user = action.payload
            state.error = null;
        },
        loginFailure: (state, action: PayloadAction<string>) => {
            state.user = null;
            state.error = action.payload;
        },
        logout: () => {
            return { ...initialState };
        },
        setSession: (state, action: PayloadAction<SessionInfoResponse>) => {
            const folder_deep_copy: Array<FolderInterface> = state.session?.folders ? JSON.parse(JSON.stringify(state.session.folders)) : []
            state.session = action.payload
            for (const folder of state.session.folders) {
                folder.files = folder_deep_copy.find(fdc => fdc.id === folder.id)?.files
            }
            state.error = null;
        },
        setFolderFiles: (state, action: PayloadAction<{ folder_id: string; files?: Array<FilesResponseInterface> }>) => {
            const index = state.session?.folders.findIndex(({ id }) => id === action.payload.folder_id) as number;
            if (state.session && index > -1) {
                state.session.folders[index].files = action.payload.files
            }
            state.error = null;
        },
        replaceFileInSessionData: (state, action: PayloadAction<FilesResponseInterface>) => {
            if (state.session?.recently_added) {
                const _index = state.session.recently_added.findIndex(d => d.id === action.payload.id)
                if (_index !== -1) state.session.recently_added[_index] = action.payload
            }
            if (state.session?.frequently_opened) {
                const _index = state.session.frequently_opened.findIndex(d => d.id === action.payload.id)
                if (_index !== -1) state.session.frequently_opened[_index] = action.payload
            }
        },
        setStarred: (state, action: PayloadAction<string[]>) => {
            if (state.session) state.session.starred = action.payload
            state.error = null;
        },
        getUsersSuccess: (state, action: PayloadAction<Array<UserInterface>>) => {
            state.users = action.payload;
        },
        setAdminSearchParams: (state, action: PayloadAction<string>) => {
            state.searches.admin = action.payload
        },
        setMembersSearchParams: (state, action: PayloadAction<string>) => {
            state.searches.members = action.payload
        },
        setFoldersSearchParams: (state, action: PayloadAction<string>) => {
            state.searches.folders = action.payload
        },
    },
});

export const {
    logout,
    setStarred,
    setSession,
    loginSuccess,
    loginFailure,
    setFolderFiles,
    getUsersSuccess,
    setAdminSearchParams,
    setFoldersSearchParams,
    setMembersSearchParams,
    replaceFileInSessionData } = authSlice.actions;
export const authReducer = authSlice.reducer;

const poolData = {
    ClientId: config.clientId,
    UserPoolId: config.userPoolId,
};

const userPool = new CognitoUserPool(poolData);
export const signUp = (email: string, password: string) => {
    return new Promise((resolve, reject) => {
        userPool.signUp(email, password, [], [], (err, result) => {
            if (err) {
                reject(err);
                return;
            }
            if (!result) {
                reject(`Unknown error occurred`);
                return;
            }
            resolve(result.user);
        });
    });
};

export const emailExists = async (email: string): Promise<CheckEmailResult> => {
    const checkEmailExists = (email: string): Promise<CheckEmailResult> => {
        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: '123',
        });
        const userData = {
            Username: email,
            Pool: userPool,
        };
        const cognitoUser = new CognitoUser(userData);
        return new Promise((resolve) => {
            cognitoUser.authenticateUser(authDetails, {
                onSuccess: () => {
                    resolve(CheckEmailResult.UserFound);
                },
                onFailure: (err) => {
                    const code = err.code;
                    switch (code) {
                        case 'UserNotFoundException':
                            return resolve(CheckEmailResult.UserNotFoundException);
                        case 'NotAuthorizedException':
                            return resolve(CheckEmailResult.NotAuthorizedException);
                        case 'PasswordResetRequiredException':
                            return resolve(CheckEmailResult.PasswordResetRequiredException);
                        case 'UserNotConfirmedException':
                            return resolve(CheckEmailResult.UserNotConfirmedException);
                        default:
                            return resolve(CheckEmailResult.UnknownError);
                    }
                },
            });
        });
    };
    return checkEmailExists(email)
};

export const signIn = (email: string, password: string): Promise<{ user: CognitoUser, session: CognitoUserSession }> => {
    const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
    });
    const userData = {
        Username: email,
        Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authDetails, {
            onSuccess: (result) => {
                resolve({ user: cognitoUser, session: result });
            },

            newPasswordRequired() {
                cognitoUser.completeNewPasswordChallenge(
                    password,
                    {
                        name: 'Unknown user' // TODO:: Fix this
                    },
                    {
                        onSuccess(result) {
                            //   const token = result.getIdToken().getJwtToken();
                            resolve({ user: cognitoUser, session: result });
                        },
                        onFailure(err) {
                            console.log(err)
                            reject(err);
                        },
                    }
                );
            },

            onFailure: (err) => {
                reject(err);
            },
        });
    });
};

export const changePassword = async (oldPassword: string, newPassword: string) => {
    const user = userPool.getCurrentUser()
    return new Promise(async (resolve, reject) => {
        if (!user) {
            return reject(`User not found!`)
        }
        await new Promise(res => user.getSession(res));
        user.changePassword(oldPassword, newPassword, (err) => {
            if (err) {
                reject(err.message || JSON.stringify(err))
                return;
            }
            resolve(true)
        });
    })
};

export const signOut = (dispatch: Dispatch) => {
    const currentUser = userPool.getCurrentUser();
    if (currentUser) {
        currentUser.signOut();
    }
    dispatch(logout())
};

export const updateProfileColorDetails = (dispatch: Dispatch, userDetails: UserInterface, profile_color: string): Promise<void> => {
    const user = userPool.getCurrentUser()
    const attributes = [
        new CognitoUserAttribute({ Name: 'profile', Value: profile_color }),
    ];
    return new Promise(async (resolve, reject) => {
        if (!user) {
            return reject(`User not found!`)
        }
        await new Promise(res => user.getSession(res));
        user.updateAttributes(attributes, (error, result) => {
            if (error) {
                return reject(error);
            }
            dispatch(loginSuccess({
                ...userDetails,
                profile_color
            }))
            resolve();
        });
    });
};

export const updateUserAddressDetails = (dispatch: Dispatch, userDetails: UserInterface, address: UserAddressInterface): Promise<void> => {
    const user = userPool.getCurrentUser()
    const attributes = [
        new CognitoUserAttribute({ Name: 'address', Value: JSON.stringify(address) }),
    ];
    return new Promise(async (resolve, reject) => {
        if (!user) {
            return reject(`User not found!`)
        }
        await new Promise(res => user.getSession(res));
        user.updateAttributes(attributes, (error, result) => {
            if (error) {
                return reject(error);
            }
            dispatch(loginSuccess({
                ...userDetails,
                address
            }))
            resolve();
        });
    });
};

export const updatePersonalInformationDetails = (dispatch: Dispatch, userDetails: UserInterface, pi: { name: string; phone_number: string; }): Promise<void> => {
    const user = userPool.getCurrentUser()
    const attributes = [
        new CognitoUserAttribute({ Name: 'name', Value: pi.name }),
        new CognitoUserAttribute({ Name: 'phone_number', Value: pi.phone_number }),
    ];
    return new Promise(async (resolve, reject) => {
        if (!user) {
            return reject(`User not found!`)
        }
        await new Promise(res => user.getSession(res));
        user.updateAttributes(attributes, (error, result) => {
            if (error) {
                return reject(error);
            }
            dispatch(loginSuccess({
                ...userDetails,
                name: pi.name,
                phone_number: pi.phone_number
            }))
            resolve();
        });
    });
};

export const registerUser = (userDetails: UserInterface, password: string) => {
    const attributeList: CognitoUserAttribute[] = [];
    attributeList.push(new CognitoUserAttribute({
        Name: 'email',
        Value: userDetails.email,
    }));
    attributeList.push(new CognitoUserAttribute({
        Name: 'name',
        Value: userDetails.name,
    }));
    attributeList.push(new CognitoUserAttribute({
        Name: 'phone_number',
        Value: userDetails.phone_number,
    }));
    attributeList.push(new CognitoUserAttribute({
        Name: 'profile',
        Value: userDetails.profile_color,
    }));

    return new Promise<{ success: boolean; error?: string }>((resolve) => {
        userPool.signUp(userDetails.email, password, attributeList, [], (error) => {
            if (error) {
                resolve({ success: false, error: error.message });
            } else {
                resolve({ success: true });
            }
        });
    });
};
