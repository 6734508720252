import './Files.scss';
import { useState } from 'react';
import GoHome from '../GoHome/GoHome';
import FileCard from '../FileCard/FileCard';
import { Grid, ListUl } from 'react-bootstrap-icons';
import { FilesResponseInterface } from '../../../interfaces/filesResponse.interface';

interface FilesProp {
    title: string;
    files: Array<FilesResponseInterface>;
}

const Files: React.FC<FilesProp> = (props: FilesProp) => {

    const alphabets = ['#', "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
    const [listView, setListView] = useState(true);
    const scrollToSection = (id: string) => {
        document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
    };
    const filesBelongingToAlphabet = (alphabet: string, files: Array<FilesResponseInterface>) => {
        return files.filter(file => alphabet === '#' ? !/^[A-Za-z]/.test(file.title) : file.title.toLowerCase().startsWith(alphabet.toLowerCase()))
    };

    return (
        <>
            <h5 className='d-flex my-4 fw-bold'>
                <span>
                    {props.title}
                </span>
                {
                    props.files.length ? <span className='ms-auto'>
                        <span className={`mx-1 mouse-pointer text-primary px-2 py-1 rounded ${listView ? 'shadow-sm' : ''}`} onClick={() => setListView(true)}>
                            <ListUl />
                        </span>
                        <span className={`mouse-pointer text-primary px-2 py-1 rounded ${!listView ? 'shadow-sm' : ''}`} onClick={() => setListView(false)}>
                            <Grid />
                        </span>
                    </span> : null
                }
            </h5>

            <div className='row'>
                {
                    !props.files.length ? <div className='col-md-12'>
                        <div className='d-flex flex-column align-items-center no-starred-found'>
                            <img src='/assets/emptyIcon.png' width={254} height={252} alt='No file found' />
                            <h3 className='mt-3'><strong>No file found</strong></h3>
                            <h6 className='text-muted mt-n2'><b>{props.title}</b> is currently empty or the search query returned no item!</h6>
                            <GoHome />
                        </div>
                    </div> : <>
                        <div className='d-flex me-4 flex-row flex-wrap alphabets-container justify-content-end'>
                            {
                                alphabets.map(alphabet => filesBelongingToAlphabet(alphabet, props.files || []).length > 0 && <small key={alphabet} onClick={() => scrollToSection(alphabet)} className="mouse-pointer">{alphabet}</small>)
                            }
                        </div>
                        <div className='col-md-12'>
                            {
                                alphabets.map(alphabet => filesBelongingToAlphabet(alphabet, props.files || []).length ? <div id={alphabet} className='row alphabet-section' key={alphabet}>
                                    <div className='col-md-1 d-flex justify-content-center'>
                                        <div className="alphabet-parent"><span>{alphabet}</span></div>
                                    </div>
                                    <div className='col-md-11 row'>
                                        {
                                            filesBelongingToAlphabet(alphabet, props.files || []).map(file => !listView ? <div key={file.id} className='col-md-4'>
                                                <FileCard file={file} />
                                            </div> : <div key={file.id} className='col-md-12'>
                                                <FileCard file={file} listView={true} />
                                            </div>)
                                        }
                                    </div>
                                </div> : null)
                            }
                        </div>
                    </>
                }
            </div>
        </>
    );
};

export default Files