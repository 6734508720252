import './Folders.scss';
import plusIcon from './plusIcon.png';
import { FormEvent, useState } from 'react';
import { RootState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import AppNav from '../../../../shared/components/AppNav/AppNav';
import { FolderPlus, Pencil, PlusLg } from 'react-bootstrap-icons';
import { Button, Col, Form, Offcanvas, Row } from 'react-bootstrap';
import { SearchPages } from '../../../../shared/enums/searchPages.enum';
import { createFolder } from '../../../../shared/services/createFolder';
import { deleteFolder } from '../../../../shared/services/deleteFolder';
import { updateFolder } from '../../../../shared/services/updateFolder';
import { FolderInterface } from '../../../../interfaces/folder.interface';
import FolderCard from '../../../../shared/components/FolderCard/FolderCard';
import ConfirmationModal from '../../../../shared/components/ConfirmationModal/ConfirmationModal';

interface NewFolderState {
    id: string;
    title: string;
    error: string;
    processing: boolean;
}

const Folders: React.FC = () => {

    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [folder, setFolder] = useState<FolderInterface>();
    const [showNewFolderCanvas, setShowNewFolderCanvas] = useState(false);
    const session = useSelector((state: RootState) => state.auth.session);
    const query = useSelector((state: RootState) => state.auth.searches.folders);
    const [folderForm, setFolderForm] = useState<NewFolderState>({ id: '', title: '', error: '', processing: false });

    const handleNewFolderSubmission = async (e: FormEvent) => {
        e.preventDefault()
        if (session?.folders.find(folder => folder.title === folderForm.title && folderForm.id !== folder.id)) {
            setFolderForm({
                ...folderForm, error: `'${folderForm.title}' already exists!`
            })
            return
        }
        if (session?.folders.find(folder => folder.title === folderForm.title && folderForm.id === folder.id)) {
            // No changes made
            setFolderForm({
                ...folderForm, id: '', title: '', error: '', processing: false
            })
            setShowNewFolderCanvas(false)
            return
        }
        setFolderForm({
            ...folderForm, processing: true
        })
        try {
            folderForm.id ? await updateFolder(dispatch, folderForm.id, folderForm.title) : await createFolder(dispatch, folderForm.title)
            setFolderForm({
                ...folderForm, title: '', error: '', processing: false
            })
            setShowNewFolderCanvas(false)
        } catch (error: any) {
            setFolderForm({
                ...folderForm, error, processing: false
            })
        }
    }

    const deleteFolderHandler = async () => {
        if (!folder) {
            setShowModal(false)
            return
        }
        try {
            await deleteFolder(dispatch, folder.id);
            setShowModal(false)
        } catch (error: any) {
            console.log(error)
        }
    }

    return (
        <>
            <AppNav placeholder='Search folders...' page={SearchPages.FOLDERS} />
            <h5 className='my-4 fw-bold'>Folders</h5>
            <div className='row'>
                <div className='col-md-3'>
                    <div className='plus-card-wrapper my-2' onClick={() => setShowNewFolderCanvas(true)}>
                        <div className="text-center py-5">
                            <div className='w-100 py-2'>
                                <img src={plusIcon} alt="Plus icon" />
                            </div>
                        </div>
                    </div>
                </div>
                {
                    session?.folders?.filter(folder => !query?.trim() || folder.title.toLowerCase().includes(query.toLowerCase())).map((folder) => <div key={folder.id} className='col-md-3'>
                        <FolderCard
                            folder={folder}
                            triggerFolderDeletion={() => { setFolder(folder); setShowModal(true) }}
                            triggerFolderTitleChange={() => { setShowNewFolderCanvas(true); setFolderForm({ ...folderForm, id: folder.id, title: folder.title, error: '' }) }}
                        />
                    </div>)
                }
            </div>
            <Offcanvas show={showNewFolderCanvas} onHide={() => setShowNewFolderCanvas(false)} key={'newFolderCanvas'} placement={'end'} name={'newFolderCanvas'}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title><FolderPlus className='me-2' /> {folderForm.id ? 'Update' : 'New'} Folder</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                        <Col md='12'>
                            <Form onSubmit={handleNewFolderSubmission}>
                                <Form.Group className="mb-3" controlId="formNewFolderName">
                                    <Form.Label>Folder name</Form.Label>
                                    <Form.Control type="text" value={folderForm.title} onChange={(e) => setFolderForm({ ...folderForm, error: '', title: e.target.value })} placeholder="Folder name" required maxLength={30} />
                                    {
                                        folderForm.error && <Form.Text className="text-danger">
                                            {folderForm.error}
                                        </Form.Text>
                                    }
                                </Form.Group>
                                <Button variant="primary" className='text-white' disabled={folderForm.processing} type="submit">
                                    {
                                        folderForm.processing ? <div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div> :
                                            folderForm.id ? <><Pencil className='me-2' />
                                                <small>Update</small>
                                            </> : <><PlusLg className='me-2' />
                                                <small>Create</small>
                                            </>
                                    }
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>

            <ConfirmationModal
                show={showModal}
                message="Are you sure you want to delete this folder?"
                onConfirm={deleteFolderHandler}
                onCancel={() => setShowModal(false)}
            />
        </>
    );
};
export default Folders;