import store from '../../store';
import axios, { AxiosInstance, AxiosError, InternalAxiosRequestConfig } from 'axios';

// Assuming you have a function to retrieve the authorization token
// Replace 'getAuthToken()' with your actual function to obtain the token
const getAuthToken = (): string | undefined => {
    const _store = store.getState()
    return _store.auth.user?.id_token
};

// Create an instance of Axios or use your preferred HTTP library
const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT
});
// Add an interceptor to modify outgoing requests
axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
        // Get the authorization token
        const authToken = getAuthToken();
        if (authToken) config.headers['Authorization'] = `Bearer ${authToken}`;
        return config;
    },
    (error: AxiosError) => {
        // Handle request error
        if (error.response) {
            console.log(error.response.data)
            return Promise.reject(error.response.data);
        }
        return Promise.reject(error);
    }
);
export const Axios = axiosInstance