import { variables } from "../variables";

const getInitials = (name: string) => {
    let initials;
    const nameSplit = name.split(" ");
    const nameLength = nameSplit.length;
    if (nameLength > 1) {
        initials =
            nameSplit[0].substring(0, 1) +
            nameSplit[nameLength - 1].substring(0, 1);
    } else if (nameLength === 1) {
        initials = nameSplit[0].substring(0, 1);
    } else return '';

    return initials.toUpperCase();
};

export const createImageFromInitials = (size: number, name: string, color = "") => {
    if (!name?.trim()) return;
    if (!color) color = "#ffffff"
    name = getInitials(name)

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d') as CanvasRenderingContext2D
    canvas.width = canvas.height = size

    context.fillStyle = color
    context.fillRect(0, 0, size, size)

    context.fillStyle = variables.primaryColor;
    context.textBaseline = 'middle'
    context.textAlign = 'center'
    context.font = `${size / 2}px Arial`
    context.fillText(name, (size / 2), (size / 2))

    return canvas.toDataURL()
};