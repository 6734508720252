import './Login.scss';
import homeImg from './home.png';
import logo from '../../../../logo.png';
import { RootState } from '../../../../store';
import React, { FormEvent, useState } from 'react';
import { ArrowRight } from 'react-bootstrap-icons';
import { Link, useNavigate } from 'react-router-dom';
import { emailExists, signIn } from '../../authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { isEmail } from '../../../../shared/lib/isEmail';
import { loginSuccess, loginFailure } from '../../authSlice';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { UserAddressInterface } from '../../../../interfaces/users.interface';
import { CheckEmailResult } from '../../../../shared/enums/checkEmailResult.enum';
import { CognitoUser, CognitoUserAttribute, CognitoUserSession } from 'amazon-cognito-identity-js';

interface LoginStateData {
    email: string;
    password: string;
    processing: boolean;
    invalid_emails: string[];
    existing_emails: string[];
}

const Login: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authError = useSelector((state: RootState) => state.auth.error);
    const [loginData, setLoginData] = useState<LoginStateData>({
        email: '',
        password: '',
        processing: false,
        invalid_emails: [],
        existing_emails: [],
    });

    const handleInputChange = (value: string, prop: 'email' | 'password') => {
        setLoginData({
            ...loginData,
            [prop]: value
        })
        dispatch(loginFailure(''));
    };

    const checkEmail = async (e: FormEvent) => {
        e.preventDefault()
        if (!isEmail(loginData.email)) {
            dispatch(loginFailure(loginData.email ? 'Invalid email address' : 'Email address is required'));
            return
        }

        if (loginData.invalid_emails.includes(loginData.email)) {
            dispatch(loginFailure(`No account is associated with the provided email ${loginData.email}`));
            return
        }

        dispatch(loginFailure(''));
        setLoginData({ ...loginData, processing: true })
        const check_result = await emailExists(loginData.email)

        if ([
            CheckEmailResult.UserFound,
            CheckEmailResult.NotAuthorizedException,
        ].includes(check_result)) {
            setLoginData({ ...loginData, existing_emails: [...loginData.existing_emails, loginData.email], processing: false })
            return
        }

        if ([
            CheckEmailResult.UserNotConfirmedException
        ].includes(check_result)) {
            setLoginData({ ...loginData, processing: false })
            dispatch(loginFailure(`Account pending verification`));
            return
        }

        if ([
            CheckEmailResult.PasswordResetRequiredException
        ].includes(check_result)) {
            setLoginData({ ...loginData, processing: false })
            dispatch(loginFailure(`Password reset required!`));
            return
        }

        setLoginData({ ...loginData, invalid_emails: [...loginData.invalid_emails, loginData.email], processing: false })
        dispatch(loginFailure(`No account is associated with the provided email ${loginData.email}`));
    };

    const handleSignIn = async (e: FormEvent) => {
        try {
            e.preventDefault()
            const signInResult = await signIn(loginData.email, loginData.password);
            signInResult.user.getUserAttributes((err, result) => {
                if (err || !result) {
                    dispatch(loginFailure(err?.message || 'Unknown error occurred'));
                    return
                }
                signInSuccess(signInResult, result)
            });
        } catch (err) {
            dispatch(loginFailure('Invalid password'));
        }
    };

    const signInAsGuest = async (e: FormEvent) => {
        try {
            e.preventDefault()
            const signInResult = await signIn('info@stretfordcoc.org', 'S8@0KZMq7EH^');
            signInResult.user.getUserAttributes((err, result) => {
                if (err || !result) {
                    dispatch(loginFailure(err?.message || 'Unknown error occurred'));
                    return
                }
                signInSuccess(signInResult, result)
            });
        } catch (err) {
            dispatch(loginFailure('Invalid password'));
        }
    };

    const signInSuccess = (signInResult: { user: CognitoUser, session: CognitoUserSession }, result: Array<CognitoUserAttribute>) => {
        let address: UserAddressInterface = result?.find(({ Name, Value }) => (Name === "address" ? Value : ''))?.Value ? JSON.parse(result.find(({ Name, Value }) => (Name === "address" ? Value : ''))?.Value as string) : null
        dispatch(loginSuccess({
            id_token: signInResult.session.getIdToken().getJwtToken(),
            refresh_token: signInResult.session.getRefreshToken().getToken(),
            access_token: signInResult.session.getAccessToken().getJwtToken(),
            sub: result?.find(({ Name, Value }) => (Name === "sub" ? Value : ''))?.Value || '',
            name: result?.find(({ Name, Value }) => (Name === "name" ? Value : ''))?.Value || '',
            email: result?.find(({ Name, Value }) => (Name === "email" ? Value : ''))?.Value || '',
            profile_pic: result?.find(({ Name, Value }) => (Name === "profile_pic" ? Value : ''))?.Value || '',
            phone_number: result?.find(({ Name, Value }) => (Name === "phone_number" ? Value : ''))?.Value || '',
            is_admin: result?.find(({ Name, Value }) => (Name === "custom:isAdmin" ? Value : ''))?.Value === 'true',
            profile_color: result?.find(({ Name, Value }) => (Name === "profile" ? Value : ''))?.Value || '#EEFFF7',
            email_verified: result?.find(({ Name, Value }) => (Name === "email_verified" ? Value : ''))?.Value === 'true',
            address: address || {
                city: '',
                street: '',
                country: '',
                post_code: '',
            }
        }))
        navigate('/')
    }

    return (
        <div className='container'>
            <div className='login-content'>
                <img src={logo} className='logo' alt="Logo" />
                <div className='home-img'>
                    <img src={homeImg} alt="Home" />
                </div>
                <Form onSubmit={loginData.existing_emails.includes(loginData.email) ? handleSignIn : checkEmail}>
                    <Row className="align-items-center mt-3">
                        <Col md={12} className="my-3">
                            <Form.Control className='bg-white' required type='email' readOnly={loginData.processing} value={loginData.email} onChange={(e) => handleInputChange(e.target.value, 'email')} placeholder="Email" />
                        </Col>

                        {
                            loginData.existing_emails.includes(loginData.email) && <Col md={12} className="mb-3">
                                <Form.Control className='bg-white' required type='password' readOnly={loginData.processing} value={loginData.password} onChange={(e) => handleInputChange(e.target.value, 'password')} placeholder="Password" />
                            </Col>
                        }
                    </Row>

                    {authError && <Alert variant='danger'>
                        <small>{authError}</small>
                    </Alert>}
                    <Button className='btn btn-primary px-3 text-white' type='submit' disabled={loginData.processing || !isEmail(loginData.email)}>
                        {
                            loginData.processing ? <div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div> : <>Next<ArrowRight color="white" className='ms-2 pb-1' size={18} /></>
                        }
                    </Button>
                </Form>
                <div className='py-2'>
                    <Link to='/register' className='small'>
                        <u>Signup</u>
                    </Link>
                    <span className='mx-3'>|</span>
                    <Link to='#' onClick={signInAsGuest} className='small'>
                        <u>Continue as guest</u>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Login;
