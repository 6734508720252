import { Axios } from "../http/Axios";
export const createFile = async (folder_id: string, title: string, path: string, disc_size: { in: 'MB', measure: number }): Promise<true> => {
    const fallback_error_message = 'Error occurred while creating a new folder';
    return new Promise((resolve, reject) => {
        Axios.post(`admins/folders/${folder_id}/files`, { title, path, disc_size })
            .then(async () => {
                try {
                    resolve(true)
                } catch (error: any) {
                    reject(error?.message || fallback_error_message)
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error?.message || fallback_error_message)
            });
    })

};