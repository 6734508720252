import { Axios } from "../http/Axios";
import { Dispatch } from "@reduxjs/toolkit";
import { FilesResponseInterface } from "../../interfaces/filesResponse.interface";
import { getStarredFilesFailure, getStarredFilesSuccess } from "../../pages/starred/starredSlice";

export const getStarredFiles = async (dispatch: Dispatch): Promise<true> => {
    const fallback_error_message = 'Error occurred while getting favorite files';
    return new Promise((resolve, reject) => {
        Axios.get(`users/files/starred`)
            .then((response: { data: { data: Array<FilesResponseInterface> } }) => {
                dispatch(getStarredFilesSuccess(response.data.data))
                resolve(true)
            })
            .catch((error) => {
                console.error(error);
                dispatch(getStarredFilesFailure(error?.message || fallback_error_message))
                reject(error?.message || fallback_error_message)
            });
    })
};