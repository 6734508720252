import './SideMenu.scss';
import logo from '../../../logo.png';
import { BarLoader } from "react-spinners";
import { RootState } from '../../../store';
import { variables } from '../../variables';
import { Accordion, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '../../../pages/auth/authSlice';
import React, { Fragment, useEffect, useState } from 'react';
import { refreshSessionData } from '../../services/refreshSessionData';
import { Link, useLocation, Outlet, useParams } from 'react-router-dom';
import { BoxArrowInRight, CloudUpload, Gear, House, People, Star, Trash, FolderFill, Person, Folder as FolderIcon, PersonFillAdd, PersonGear, List } from 'react-bootstrap-icons';
import ScrollToTop from '../ScrollToTop/ScrollToTop';

const SideMenuForFiles: React.FC = () => {
    const { slug } = useParams();
    const location = useLocation()
    const authDetails = useSelector((state: RootState) => state.auth);
    const { user, session } = authDetails;
    return (
        <>
            {
                user?.is_admin && <Link to={`/settings/folders${slug ? '/' + slug : ''}`} className='btn btn-primary px-3 text-white text-start'>
                    <CloudUpload className='me-2' /> Upload
                </Link>
            }
            <div className='py-3'>
                {
                    session?.folders?.length ? <Accordion defaultActiveKey="0" alwaysOpen flush>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><FolderIcon className="text-primary me-2" /> Folders</Accordion.Header>
                            <Accordion.Body className='py-0'>
                                <ul className="list-group">
                                    {
                                        session.folders.map(folder => <Link key={folder.id} to={`/folders/${folder.slug}`}><li className="list-group-item text-truncate"><FolderFill className="text-primary me-2" /> {folder.title}</li></Link>)
                                    }
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion> : null
                }

                <ul className="list-group">
                    <Link to={`/starred`} className={location.pathname.startsWith('/starred') ? 'active' : ''}><li className="list-group-item mb-1"><Star className="text-primary me-2" /> Starred</li></Link>
                    {
                        user?.is_admin &&
                        <Link to={`/trash`} className={location.pathname.startsWith('/trash') ? 'active' : ''}><li className="list-group-item mb-1"><Trash className="text-primary me-2" /> Trash</li></Link>
                    }
                </ul>
            </div>
        </>
    );
};

const SideMenuForUsers: React.FC = () => {
    const location = useLocation()
    const userDetails = useSelector((state: RootState) => state.auth.user);
    return (
        <>
            {
                userDetails?.is_admin && <Button id='new_person_add' className='btn btn-primary px-3 text-white text-start'>
                    <PersonFillAdd className='me-2' /> Create
                </Button>
            }
            <div className='py-3'>
                <ul className="list-group">
                    <Link to={'/users/members'} className={location.pathname === '/users/members' ? 'active' : ''}>
                        <li className={`list-group-item mb-1`}><People className="text-primary me-2" /> Members</li>
                    </Link>
                    <Link to={'/users/admins'} className={location.pathname === '/users/admins' ? 'active' : ''}>
                        <li className={`list-group-item mb-1`}><PersonGear className="text-primary me-2" /> Admins</li>
                    </Link>
                </ul>
            </div>
        </>
    );
};

const SideMenuForSettings: React.FC = () => {
    const location = useLocation()
    const userDetails = useSelector((state: RootState) => state.auth.user);
    return (
        <>
            <div className='py-3'>
                <ul className="list-group">
                    <Link to={'/settings/my-profile'} className={location.pathname === '/settings/my-profile' ? 'active' : ''}>
                        <li className={`list-group-item mb-1`}><Person className="text-primary me-2" /> My Profile</li>
                    </Link>
                    {
                        userDetails?.is_admin &&
                        <Link to={'/settings/folders'} className={location.pathname.includes('/settings/folders') ? 'active' : ''}>
                            <li className="list-group-item mb-1"><FolderIcon className="text-primary me-2" /> Folders</li>
                        </Link>
                    }
                </ul>
            </div>
        </>
    );
};

const SideMenu: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [showMenuWrapper, setShowMenuWrapper] = useState(true)
    const authDetails = useSelector((state: RootState) => state.auth);
    const { user, session } = authDetails;

    let sideMenuContent = <SideMenuForFiles />;
    if (location.pathname.includes('/users/')) {
        sideMenuContent = <SideMenuForUsers />;
    }
    if (location.pathname.includes('/settings/')) {
        sideMenuContent = <SideMenuForSettings />;
    }

    useEffect(() => {
        refreshSessionData(dispatch)
    }, [dispatch])

    return (
        <>
            {
                session ? <Fragment>
                    <div className="sidenav d-flex flex-row">
                        <div className={`iconWrapper d-flex flex-column  ${showMenuWrapper ? 'showingMenuWrapper' : ''}`}>
                            <div className='iconParent mobile-side-menu-controller' onClick={() => setShowMenuWrapper(!showMenuWrapper)}>
                                <List />
                            </div>
                            <Link to={'/'} className={`iconParent ${!location.pathname.startsWith('/users/') && !location.pathname.startsWith('/settings/') ? 'active' : ''}`}>
                                <House />
                            </Link>
                            {
                                user?.is_admin && <Link to={'/users/members'} className={`iconParent ${location.pathname.startsWith('/users/') ? 'active' : ''}`}>
                                    <People />
                                </Link>
                            }
                            <Link to={'/settings/my-profile'} className={`iconParent ${location.pathname.startsWith('/settings') ? 'active' : ''}`}>
                                <Gear />
                            </Link>
                            <div className="mt-auto iconParent" onClick={() => signOut(dispatch)}>
                                <BoxArrowInRight />
                            </div>
                        </div>
                        <div className={`menuWrapper d-flex flex-column ${showMenuWrapper ? 'show' : 'hide'}`}>
                            <Link to={'/'}><img src={logo} className='logo' alt="Logo" /></Link>
                            {sideMenuContent}
                        </div>
                    </div>
                    <div className="main">
                        <Outlet />
                        <ScrollToTop />
                    </div>
                </Fragment> : <BarLoader
                    color={variables.primaryColor}
                    loading={true}
                    cssOverride={{
                        display: "block",
                        margin: "0 auto",
                        marginTop: '400px'
                    }}
                    width={200}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            }
        </>
    );
};

export default SideMenu;
