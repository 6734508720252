import toast from "react-hot-toast";
import { Axios } from "../http/Axios";
import { Dispatch } from "@reduxjs/toolkit";
import { getUsersSuccess } from "../../pages/auth/authSlice";
import { UserInterface } from "../../interfaces/users.interface";

export const deleteUser = async (dispatch: Dispatch, { sub }: UserInterface) => {
    Axios.delete(`users/${sub}`)
        .then((response: { data: { data: Array<UserInterface> } }) => {
            dispatch(getUsersSuccess(response.data.data))
        })
        .catch((error) => {
            console.error(error);
            toast.error(error?.message || 'Error occurred while deleting user')
        });
};
