import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilesResponseInterface } from '../../interfaces/filesResponse.interface';

interface StarredState {
    files: {
        error: string;
        data: Array<FilesResponseInterface> | null;
    }
}

const initialState: StarredState = {
    files: {
        error: '',
        data: null,
    }
};

const starredSlice = createSlice({
    name: 'starred',
    initialState,
    reducers: {
        getStarredFilesSuccess: (state, action: PayloadAction<Array<FilesResponseInterface>>) => {
            state.files.error = '';
            state.files.data = action.payload
        },
        getStarredFilesFailure: (state, action: PayloadAction<string>) => {
            state.files.data = null;
            state.files.error = action.payload;
        },
    },
});

export const { getStarredFilesSuccess, getStarredFilesFailure } = starredSlice.actions;
export const starredReducer = starredSlice.reducer;
