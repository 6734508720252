import { Axios } from "../http/Axios";
import { FilesResponseInterface } from "../../interfaces/filesResponse.interface";

export const getPrevNextFile = async (file_id: string, direction: string): Promise<FilesResponseInterface> => {
    return new Promise((resolve, reject) => {
        Axios.get(`users/files/navigation/${file_id}/${direction}`)
            .then((response: { data: { data: FilesResponseInterface } }) => {
                response.data.data ? resolve(response.data.data) : reject('File not found')
            })
            .catch((error) => {
                console.error(error);
                reject(error?.message || 'Error occurred while fetching file')
            });
    })
};
