import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import AppNav from '../../../shared/components/AppNav/AppNav';
import FileCard from '../../../shared/components/FileCard/FileCard';
import { SearchPages } from '../../../shared/enums/searchPages.enum';

const Dashboard: React.FC = () => {
    const session = useSelector((state: RootState) => state.auth.session);
    return (
        <>
            <AppNav placeholder='Select folder to search files' searchDisabled={true} page={SearchPages.DASHBOARD} />
            {
                session?.frequently_opened?.length ? <>
                    <h5 className='my-4 fw-bold'>Frequently opened</h5>
                    <div className='row'>
                        {
                            session?.frequently_opened.map(file => <div key={file.id} className='col-md-3'>
                                <FileCard file={file} />
                            </div>)
                        }

                    </div>
                </> : null
            }
            {
                session?.recently_added?.length ? <>
                    <h5 className='my-4 fw-bold'>Recently added</h5>
                    <div className='row'>
                        {
                            session?.recently_added.map(file => <div key={file.id} className='col-md-3'>
                                <FileCard file={file} />
                            </div>)
                        }

                    </div>
                </> : null
            }
        </>
    );
};

export default Dashboard