import './GoHome.scss';
import { Link } from "react-router-dom";
import { HouseCheck } from "react-bootstrap-icons";

const GoHome: React.FC = () => {
    return (<Link to={`/`} className='go-home-btn my-2 text-white text-start'>
        <HouseCheck className='me-2' /> Go home
    </Link>
    );
};
export default GoHome;
