import { Axios } from "../http/Axios";
import { Dispatch } from "@reduxjs/toolkit";
import { Paginated } from "../../interfaces/paginated.interface";
import { getFilesFailure, getFilesSuccess } from "../../pages/FolderContents/folderContentsSlice";
import { FilesResponseInterface } from "../../interfaces/filesResponse.interface";
import { FileRequestFilter } from "../../interfaces/fileRequest.interface";
import { setFolderFiles } from "../../pages/auth/authSlice";

export const getFiles = async (dispatch: Dispatch, folder_id: string, filters_obj: FileRequestFilter) => {
    const filters = Object.entries(filters_obj).map(e => e.join('=')).join('&');
    Axios.get(`users/folders/${folder_id}/files?${filters}`)
        .then((response: { data: { data: Paginated<FilesResponseInterface> } }) => {
            if (!filters_obj.limit) {
                // fetched all files in folder
                dispatch(setFolderFiles({ folder_id, files: response.data.data.data }))
            }
            dispatch(getFilesSuccess({ folder_id, data: response.data.data }))
        })
        .catch((error) => {
            console.error(error);
            dispatch(getFilesFailure(error?.message || 'Error occurred while fetching files'))
        });
};
