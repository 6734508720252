import './FileCard.scss';
import { toast } from 'react-hot-toast';
import { RootState } from '../../../store';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../lib/formatDate';
import { starFile } from '../../services/starFile';
import { useDispatch, useSelector } from 'react-redux';
import { unstarFile } from '../../services/unstarFile';
import { recoverFiles } from '../../services/recoverFiles';
import { setStarred } from '../../../pages/auth/authSlice';
import { getTrashFilesSuccess } from '../../../pages/trash/trashSlice';
import { ArrowCounterclockwise, Star, StarFill } from 'react-bootstrap-icons';
import { FilesResponseInterface } from '../../../interfaces/filesResponse.interface';
import { SessionInfoResponse } from '../../../interfaces/sessionInfoResponse.interface';

interface FileCardProps {
    listView?: boolean;
    file: FilesResponseInterface;
}

const FileCard: React.FC<FileCardProps> = (props: FileCardProps) => {
    const { file } = props

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector((state: RootState) => state.auth.user);
    const folders = useSelector((state: RootState) => state.auth.session?.folders) || [];
    const session = useSelector((state: RootState) => state.auth.session) as SessionInfoResponse;
    const trash = useSelector((state: RootState) => state.trash.request.data) as Array<FilesResponseInterface>;

    const highLightFile = () => {
        if (!file.deleted_at) {
            const folder = folders.find(folder => folder.id === file.folder_id)
            navigate(folder ? `/folders/${folder.slug}/${file.id}` : '/404')
        }
    };

    const star = async () => {
        try {
            dispatch(setStarred([...session.starred, file.id]))
            await starFile(file.id)
        } catch (error: any) {
            toast.error(error);
            dispatch(setStarred([...session.starred.filter(s => s !== file.id)]))
        }
    }

    const undoStarred = async () => {
        try {
            dispatch(setStarred([...session.starred.filter(s => s !== file.id)]))
            await unstarFile(file.id)
        } catch (error: any) {
            toast.error(error);
            dispatch(setStarred([...session.starred, file.id]))
        }
    }

    const undoDelete = async () => {
        try {
            dispatch(getTrashFilesSuccess([...trash.filter(s => s.id !== file.id)]))
            await recoverFiles(file.id)
        } catch (error: any) {
            toast.error(error);
            dispatch(getTrashFilesSuccess([...trash, file]))
        }
    }

    return (
        <>
            {
                props.listView ? <div className={`file-card-wrapper list px-3 pt-2 pb-1 my-1 ${file.deleted_at ? '' : 'mouse-pointer'}`} onClick={highLightFile}>
                    <div className="d-flex">
                        <img src={`/assets/${file.ext}Icon.png`} width={20} height={20} alt={`${file.ext} Icon`} />
                        <h6 className='text-truncate pt-1 ms-2'><strong>{file.title}</strong></h6>
                        {
                            !user?.is_admin && file.deleted_at ? null :
                                <div className="ms-auto">
                                    {
                                        file.deleted_at ?
                                            <ArrowCounterclockwise onClick={(e) => { e.preventDefault(); e.stopPropagation(); undoDelete() }} className='text-success mt-n1 mouse-pointer' size={14} /> :
                                            session?.starred?.includes(file.id) ?
                                                <StarFill onClick={(e) => { e.preventDefault(); e.stopPropagation(); undoStarred() }} className='text-success mt-n1 mouse-pointer' size={14} /> :
                                                <Star onClick={(e) => { e.preventDefault(); e.stopPropagation(); star() }} className='text-muted mt-n1 mouse-pointer' size={14} />
                                    }
                                </div>
                        }
                    </div>
                </div> : <div className={`file-card-wrapper grid p-3 my-2 ${file.deleted_at ? '' : 'mouse-pointer'}`} onClick={highLightFile}>
                    <div className="d-flex justify-content-between">
                        <div className='w-100'>
                            <img src={`/assets/${file.ext}Icon.png`} width={40} height={40} alt={`${file.ext} Icon`} />
                        </div>
                        {
                            !user?.is_admin && file.deleted_at ? null :
                                <div className="profile-pic">
                                    {
                                        file.deleted_at ?
                                            <ArrowCounterclockwise onClick={(e) => { e.preventDefault(); e.stopPropagation(); undoDelete() }} className='text-success mouse-pointer' size={18} /> :
                                            session?.starred?.includes(file.id) ?
                                                <StarFill onClick={(e) => { e.preventDefault(); e.stopPropagation(); undoStarred() }} className='text-success mouse-pointer' size={18} /> :
                                                <Star onClick={(e) => { e.preventDefault(); e.stopPropagation(); star() }} className='text-muted mouse-pointer' size={18} />
                                    }
                                </div>
                        }
                    </div>
                    <div className="d-flex flex-column mt-3 px-1">
                        <h6 className='text-truncate'><strong>{file.title}</strong></h6>
                        <p className='mt-n2'>{formatDate(file.creation_date)}</p>
                        <small className='mt-n3 text-muted'>{file.disc_size}</small>
                    </div>
                </div>
            }

        </>
    );
};

export default FileCard