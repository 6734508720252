import { Axios } from "../http/Axios";
import { PresignedPolicy } from "../../interfaces/presignedPolicy.interface";

export const getUploadURL = async (folder_id: string): Promise<PresignedPolicy> => {
    return new Promise((resolve, reject) => {
        Axios.get(`admins/folders/${folder_id}/files`)
            .then((response: { data: { data: PresignedPolicy } }) => {
                resolve(response.data.data)
            })
            .catch((error) => {
                console.error(error);
                reject(error?.message || 'Error occurred while fetching files')
            });
    })

};