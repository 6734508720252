import './Starred.scss';
import { useEffect } from 'react';
import { BarLoader } from 'react-spinners';
import { RootState } from '../../../store';
import { variables } from '../../../shared/variables';
import { useDispatch, useSelector } from 'react-redux';
import Files from '../../../shared/components/Files/Files';
import AppNav from '../../../shared/components/AppNav/AppNav';
import { SearchPages } from '../../../shared/enums/searchPages.enum';
import { getStarredFiles } from '../../../shared/services/getStarredFiles';

const Starred: React.FC = () => {

    const session = useSelector((state: RootState) => state.auth.session);
    const files = useSelector((state: RootState) => state.starred.files.data);
    const query = useSelector((state: RootState) => state.files.starred_search.param);
    const dispatch = useDispatch()

    useEffect(() => {
        if (session?.starred?.length) {
            getStarredFiles(dispatch)
        }
    }, [dispatch, session?.starred?.length])

    return (
        <>
            <AppNav placeholder='Search starred...' searchDisabled={!session?.starred?.length} page={SearchPages.STARRED} />
            {
                !files ? <BarLoader
                    color={variables.primaryColor}
                    loading={true}
                    cssOverride={{
                        display: "block",
                        margin: "0 auto",
                        marginTop: '200px'
                    }}
                    width={200}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                /> : <Files title="Starred" files={files.filter(file => session?.starred.includes(file.id) && (!query?.trim() || file.title.toLowerCase().includes(query.toLowerCase())))} />
            }
        </>
    );
};

export default Starred