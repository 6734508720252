/* eslint-disable react-hooks/exhaustive-deps */
import { RootState } from '../../../store';
import { BarLoader } from 'react-spinners';
import { variables } from '../../../shared/variables';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Files from '../../../shared/components/Files/Files';
import { getFiles } from '../../../shared/services/getFiles';
import AppNav from '../../../shared/components/AppNav/AppNav';
import { SearchPages } from '../../../shared/enums/searchPages.enum';
import { FolderInterface } from '../../../interfaces/folder.interface';

const FolderContents: React.FC = () => {

    const { slug } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const session = useSelector((state: RootState) => state.auth.session);
    const file_request = useSelector((state: RootState) => state.files.request);
    const [folder, setFolder] = useState<FolderInterface>(session?.folders?.find(folder => folder.slug === slug) as FolderInterface)

    const handlePageChange = useCallback(() => {
        if (!folder.files) {
            getFiles(dispatch, folder.id, { ...file_request.param, search: '', sort: 'asc', sort_by: 'title', page: 1, limit: 0 })
        }
    }, [slug, folder.item_count]);

    useEffect(() => {
        const folder = session?.folders?.find(folder => folder.slug === slug) as FolderInterface;
        if (!folder) {
            navigate('/404')
        }
        setFolder(folder)
        // Fetch files
        handlePageChange()
    }, [slug, folder.item_count])

    useEffect(() => {
        setFolder(session?.folders?.find(folder => folder.slug === slug) as FolderInterface)
    }, [session?.folders])

    return (
        <>
            <AppNav placeholder={`Search ${folder.title}...`} page={SearchPages.FILES} searchDisabled={!folder.files} />
            {
                !folder?.files ? <BarLoader
                    color={variables.primaryColor}
                    loading={true}
                    cssOverride={{
                        display: "block",
                        margin: "0 auto",
                        marginTop: '200px'
                    }}
                    width={200}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                /> : <Files files={folder.files} title={folder.title} />
            }
        </>
    );
};

export default FolderContents