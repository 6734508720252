import './FolderCard.scss';
import { useState } from 'react';
import pluralize from 'pluralize';
import folderIcon from './folderIcon.png';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../lib/formatDate';
import { FolderInterface } from '../../../interfaces/folder.interface';
import { Pencil, ThreeDotsVertical, Trash } from 'react-bootstrap-icons';

interface FolderCardProp {
    folder: FolderInterface;
    triggerFolderDeletion: Function;
    triggerFolderTitleChange: Function;
}

const FolderCard: React.FC<FolderCardProp> = (props: FolderCardProp) => {

    const { folder } = props
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false);

    const highLightFolder = () => {
        navigate('/settings/folders/' + folder.slug)
    };

    return (
        <div className='folder-card-wrapper my-2 mouse-pointer' onClick={highLightFolder}>
            <div className="d-flex justify-content-between">
                <div className='w-100'>
                    <img src={folderIcon} alt="Folder icon" />
                </div>
                <div className="profile-pic">
                    <Dropdown className="d-inline custom-dropdown" show={isOpen} onToggle={() => setIsOpen(!isOpen)}>
                        <Dropdown.Toggle className="custom-dropdown-toggle" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setIsOpen(!isOpen) }}>
                            <ThreeDotsVertical className='text-muted' size={18} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item className="custom-dropdown-item text-muted" onClick={(e) => { e.preventDefault(); setIsOpen(false); e.stopPropagation(); props.triggerFolderTitleChange() }}><Pencil className='me-2' /> Rename</Dropdown.Item>
                            <Dropdown.Item className="custom-dropdown-item text-danger" onClick={(e) => { e.preventDefault(); setIsOpen(false); e.stopPropagation(); props.triggerFolderDeletion() }}><Trash className='me-2' /> Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className="d-flex flex-column mt-3 px-1">
                <h6 className='text-truncate'><strong>{folder.title}</strong></h6>
                <p className='mt-n2'>{formatDate(folder.creation_date)}</p>
                <small className='mt-n3 text-muted'>{folder.item_count} {pluralize('item', folder.item_count || 1)}</small>
                <small className='text-muted'>{folder.disc_size}</small>
            </div>
        </div>
    );
};

export default FolderCard