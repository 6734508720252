import toast from "react-hot-toast";
import { Axios } from "../http/Axios";
import { Dispatch } from "@reduxjs/toolkit";
import { getUsersSuccess } from "../../pages/auth/authSlice";
import { UserInterface } from "../../interfaces/users.interface";

export const modifyUser = async (dispatch: Dispatch, user: UserInterface) => {
    Axios.patch(`users/${user.sub}`, { name: user.name, is_admin: user.is_admin })
        .then((response: { data: { data: Array<UserInterface> } }) => {
            dispatch(getUsersSuccess(response.data.data))
        })
        .catch((error) => {
            console.error(error);
            toast.error(error?.message || 'Error occurred while updating user')
        });
};
