import { Axios } from "../http/Axios";
import { Dispatch } from "@reduxjs/toolkit";
import { replaceFileInSessionData } from "../../pages/auth/authSlice";
import { FilesResponseInterface } from "../../interfaces/filesResponse.interface";
import { replaceFile } from "../../pages/FolderContents/folderContentsSlice";

export const setOpenedFile = async (dispatch: Dispatch, file_id: string) => {
    Axios.get(`users/files/opened/${file_id}`)
        .then((response: { data: { data: FilesResponseInterface } }) => {
            dispatch(replaceFile(response.data.data))
            dispatch(replaceFileInSessionData(response.data.data))
        })
        .catch((error) => {
            console.error(error);
        });
};