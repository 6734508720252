import './Folder.scss';
import moment from 'moment';
import pluralize from 'pluralize';
import { toast } from 'react-hot-toast';
import { RootState } from '../../../../store';
import { FileUploader } from "react-drag-drop-files";
import { useDispatch, useSelector } from 'react-redux';
import { variables } from '../../../../shared/variables';
import { setFolderFiles } from '../../../auth/authSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { getFiles } from '../../../../shared/services/getFiles';
import AppNav from '../../../../shared/components/AppNav/AppNav';
import { directUpload } from '../../../../shared/lib/directUpload';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { createFile } from '../../../../shared/services/createFile';
import { deleteFile } from '../../../../shared/services/deleteFile';
import { updateFile } from '../../../../shared/services/updateFile';
import { SearchPages } from '../../../../shared/enums/searchPages.enum';
import { FolderInterface } from '../../../../interfaces/folder.interface';
import Paginator from '../../../../shared/components/Paginator/Paginator';
import { FileRequestFilter } from '../../../../interfaces/fileRequest.interface';
import { Eye, File, Pencil, ThreeDotsVertical, Trash } from 'react-bootstrap-icons';
import { FilesResponseInterface } from '../../../../interfaces/filesResponse.interface';
import ConfirmationModal from '../../../../shared/components/ConfirmationModal/ConfirmationModal';
import { Breadcrumb, Button, Col, Dropdown, Form, Offcanvas, ProgressBar, Row } from 'react-bootstrap';

const Folder: React.FC = () => {
    const { slug } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [files, setFiles] = useState<Array<File>>();
    const [uploadingCount, setUploadingCount] = useState(1);
    const session = useSelector((state: RootState) => state.auth.session);
    const search_results = useSelector((state: RootState) => state.files.request.data);
    const [fileForm, setFileForm] = useState({ title: '', error: '', processing: false });
    const folder = session?.folders?.find(folder => folder.slug === slug) as FolderInterface
    const [stagedFileForDeletion, setStagedFileForDeletion] = useState<FilesResponseInterface | null>(null);
    const [stagedFileForTitleUpdate, setStagedFileForTitleUpdate] = useState<FilesResponseInterface | null>(null);

    if (!folder) {
        navigate('/404')
    }
    const [filesRequestFiles, setFilesRequestFiles] = useState<FileRequestFilter>({
        search: '',
        sort: 'desc',
        sort_by: 'creation_date',
        limit: search_results?.perPage || 30,
        page: search_results?.currentPage || 1,
    });

    const handleChange = async (files: Array<File>) => {
        setFiles(files);
        try {
            await toast.promise(
                new Promise(async (resolve, reject) => {
                    try {
                        for (const file of files) {
                            const file_path = await directUpload(file, folder.id)
                            await createFile(folder.id, file.name.split('.').slice(0, -1).join('.'), file_path, { measure: +((file.size / (1024 * 1024)).toFixed(2)), in: 'MB' })
                            setUploadingCount(uploadingCount + 1)
                        }
                        setFiles([]);
                        handlePageChange();
                        resolve(true)
                    } catch (error) {
                        reject(error)
                    }
                }),
                {
                    loading: 'Uploading...',
                    success: <b>All {files.length} {pluralize('file', files.length)} uploaded!</b>,
                    error: <b>Error occurred during upload!</b>,
                }
            );
        } catch (error) {
            if (typeof error === typeof "") {
                toast.error(error as string)
            }
            setFiles([])
        }
        dispatch(setFolderFiles({ folder_id: folder.id }))
    };

    const handlePageChange = useCallback(async () => {
        getFiles(dispatch, folder.id, { ...filesRequestFiles, limit: 100 })
    }, [dispatch, filesRequestFiles, folder.id]);

    useEffect(() => {
        // Fetch files
        handlePageChange()
    }, [folder, handlePageChange])

    const deleteFileHandler = async () => {
        if (!folder || !stagedFileForDeletion) {
            setStagedFileForDeletion(null)
            return
        }
        try {
            await toast.promise(
                deleteFile(dispatch, folder.id, stagedFileForDeletion.id),
                {
                    loading: 'Deleting...',
                    success: <b>{stagedFileForDeletion.title} successfully deleted!</b>,
                    error: <b>Error occurred during deletion!</b>,
                }
            );
        } catch (error) {
            console.log(error)
        }
        setStagedFileForDeletion(null)
    }

    const startFileEdit = async (_file: FilesResponseInterface) => {
        if (fileForm.processing) {
            toast.error('An update is currently running, please wait...')
            return
        }
        setFileForm({
            error: '',
            processing: false,
            title: _file.title,
        })
        setStagedFileForTitleUpdate(_file)
    }

    const handleFileTitleUpdateSubmission = async (e: FormEvent) => {
        e.preventDefault()
        if (!folder || !stagedFileForTitleUpdate || fileForm.processing || fileForm.title === stagedFileForTitleUpdate.title) {
            setStagedFileForTitleUpdate(null)
            return
        }
        setFileForm({
            ...fileForm,
            error: '',
            processing: true,
        })
        try {
            await toast.promise(
                updateFile(dispatch, folder.id, stagedFileForTitleUpdate.id, fileForm.title),
                {
                    loading: 'Updating...',
                    success: <b>{stagedFileForTitleUpdate.title} successfully updated!</b>,
                    error: <b>Error occurred during update!</b>,
                }
            );
        } catch (error) {
            console.log(error)
        }
        setFileForm({
            ...fileForm,
            error: '',
            processing: false,
        })
        setStagedFileForTitleUpdate(null)
    }

    return (
        <>
            <AppNav placeholder={`Search ${folder.title}...`} page={SearchPages.FILES} searchDisabled={!folder.files} />
            <Breadcrumb className='my-4 fw-bold'>
                <Breadcrumb.Item href="/settings/folders">Folders</Breadcrumb.Item>
                <Breadcrumb.Item active>{folder.title}</Breadcrumb.Item>
            </Breadcrumb>
            <div className='row'>
                <div className="col-md-12">
                    <FileUploader classes="upload-pane" handleChange={handleChange} dropMessageStyle={{
                        'opacity': '0.3',
                        'border': 'none',
                        'color': 'white',
                        'fontWeight': 'bold',
                        'backgroundColor': variables.primaryColor
                    }} multiple={true} name="file" types={variables.fileTypes} />
                    {
                        files?.length && files.length > 0 ? <ProgressBar className='my-3' now={(uploadingCount * 100) / files.length + 1} label={`${uploadingCount}/${files.length}`} /> : null
                    }

                </div>

                {
                    search_results?.data?.length ? <div className="col-md-12">
                        <div className='app-table mt-5'>
                            <table className='w-100'>
                                <thead>
                                    <tr>
                                        <th style={{ 'width': '75%' }}>Name</th>
                                        <th>Last opened</th>
                                        <th>Views</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        search_results.data.map(data => {
                                            return <tr key={data.id}>
                                                <td className='mouse-pointer'>
                                                    <img src={`/assets/${data.ext}Icon.png`} width={30} height={30} alt={`${data.ext} Icon`} />
                                                    <strong className='ps-3'>{data.title}</strong>
                                                </td>
                                                <td><span className='fw-light small'>{data.last_opened ? moment(data.last_opened).fromNow() : '-'}</span></td>
                                                <td><span className='fw-light small'>{data.opened_count.toLocaleString()}</span></td>
                                                <td className='text-end pe-3'>
                                                    <Dropdown className="d-inline custom-dropdown">
                                                        <Dropdown.Toggle className="custom-dropdown-toggle">
                                                            <ThreeDotsVertical className='text-muted mouse-pointer' size={18} />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item target="_blank" href={`/folders/${folder.slug}/${data.id}`} className={`custom-dropdown-item text-muted ${data.deleted_at ? 'div-disabled' : ''}`}><Eye className='me-2' /> Open</Dropdown.Item>
                                                            <Dropdown.Item className="custom-dropdown-item text-muted" onClick={(e) => { startFileEdit(data) }}><Pencil className='me-2' /> Rename</Dropdown.Item>
                                                            <Dropdown.Item className="custom-dropdown-item text-danger" onClick={(e) => { setStagedFileForDeletion(data) }}><Trash className='me-2' /> Delete</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className='d-flex flex-row mt-4 justify-content-between'>
                            <div className='small texted-muted'>
                                <strong>{search_results.total}</strong> total {pluralize('record', search_results.total)}
                            </div>
                            <Paginator
                                total={search_results.total}
                                page={filesRequestFiles.page}
                                current={search_results.currentPage}
                                totalPages={search_results.totalPages}
                                next_page={(next_page: number) => { setFilesRequestFiles({ ...filesRequestFiles, page: next_page }); handlePageChange() }}
                            />
                        </div>
                    </div> : null
                }
            </div>
            <Offcanvas show={!!stagedFileForTitleUpdate} onHide={() => setStagedFileForTitleUpdate(null)} key={'updateFileTitleCanvas'} placement={'end'} name={'updateFileTitleCanvas'}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title><File className='me-2' /> Update File</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                        <Col md='12'>
                            <Form onSubmit={handleFileTitleUpdateSubmission}>
                                <Form.Group className="mb-3" controlId="formUpdateFileName">
                                    <Form.Label>File name</Form.Label>
                                    <Form.Control type="text" value={fileForm.title} onChange={(e) => setFileForm({ ...fileForm, error: '', title: e.target.value })} placeholder="File name" required maxLength={120} />
                                    {
                                        fileForm.error && <Form.Text className="text-danger">
                                            {fileForm.error}
                                        </Form.Text>
                                    }
                                </Form.Group>
                                <Button variant="primary" className='text-white' disabled={fileForm.processing} type="submit">
                                    {
                                        fileForm.processing ? <div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div> :
                                            <><Pencil className='me-2' />
                                                <small>Update</small>
                                            </>
                                    }
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
            <ConfirmationModal
                show={!!stagedFileForDeletion}
                message="Are you sure you want to delete this file?"
                onConfirm={deleteFileHandler}
                onCancel={() => setStagedFileForDeletion(null)}
            />
        </>
    );
};

export default Folder