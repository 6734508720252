import { Axios } from "../http/Axios";
export const starFile = async (file_id: string): Promise<true> => {
    const fallback_error_message = 'Error occurred while setting favorite files';
    return new Promise((resolve, reject) => {
        Axios.put(`users/files/starred/${file_id}`, {})
            .then(async () => {
                try {
                    resolve(true)
                } catch (error: any) {
                    reject(error?.message || fallback_error_message)
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error?.message || fallback_error_message)
            });
    })

};