/* eslint-disable react-hooks/exhaustive-deps */
import './Trash.scss';
import pluralize from 'pluralize';
import { BarLoader } from 'react-spinners';
import { RootState } from '../../../store';
import { Col, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { variables } from '../../../shared/variables';
import { useDispatch, useSelector } from 'react-redux';
import { Trash as TrashIcon } from 'react-bootstrap-icons';
import AppNav from '../../../shared/components/AppNav/AppNav';
import GoHome from '../../../shared/components/GoHome/GoHome';
import FileCard from '../../../shared/components/FileCard/FileCard';
import { SearchPages } from '../../../shared/enums/searchPages.enum';
import { getTrashedFiles } from '../../../shared/services/getTrashedFiles';
import { getTrashFilesSuccess } from '../trashSlice';
import { recoverFiles } from '../../../shared/services/recoverFiles';
import { toast } from 'react-hot-toast';
import { permanentlyDeleteFile } from '../../../shared/services/permanentlyDeleteFile';
import { refreshSessionData } from '../../../shared/services/refreshSessionData';

const Trash: React.FC = () => {

    const [trashSize, setTrashSize] = useState('0 MB')
    const trash = useSelector((state: RootState) => state.trash.request);
    const query = useSelector((state: RootState) => state.trash.trash_search.param);
    const dispatch = useDispatch()

    useEffect(() => {
        getTrashedFiles(dispatch)
    }, [])

    useEffect(() => {
        if (trash.data) {
            const formatBytes = (a: number, b = 2) => { if (!+a) return "0 Bytes"; const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1000)); return `${parseFloat((a / Math.pow(1000, d)).toFixed(c))} ${["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]}` }
            let kb_size = 0;
            let mb_size = 0;
            let gb_size = 0;
            for (const file of trash.data) {
                const disc_size = file.disc_size.split(' ')
                if (file.disc_size.toLowerCase().endsWith('mb')) {
                    mb_size += +disc_size[0]
                    continue
                }
                if (file.disc_size.toLowerCase().endsWith('kb')) {
                    kb_size += +disc_size[0]
                    continue
                }
                if (file.disc_size.toLowerCase().endsWith('gb')) {
                    gb_size += +disc_size[0]
                    continue
                }
            }
            setTrashSize(formatBytes((kb_size * 1000) + (mb_size * 1000 * 1000) + (gb_size * 1000 * 1000 * 1000)))
        }
    }, [trash.data])

    const emptyTrash = async () => {
        try {
            await permanentlyDeleteFile()
            refreshSessionData(dispatch)
            dispatch(getTrashFilesSuccess([]))
        } catch (error: any) {
            toast.error(error);
        }
    }

    const undoDelete = async () => {
        try {
            await recoverFiles()
            refreshSessionData(dispatch)
            dispatch(getTrashFilesSuccess([]))
        } catch (error: any) {
            toast.error(error);
        }
    }

    return (
        <>
            <AppNav placeholder='Search trashed...' searchDisabled={!trash?.data?.length} page={SearchPages.TRASH} />
            <h5 className='my-4 fw-bold'>Trash</h5>
            <Row className='m-0'>
                {
                    !trash.data ? <BarLoader
                        color={variables.primaryColor}
                        loading={true}
                        cssOverride={{
                            display: "block",
                            margin: "0 auto",
                            marginTop: '200px'
                        }}
                        width={200}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    /> : <>

                        {
                            !trash?.data?.length ? <Col className='no-starred-found' md='12'>
                                <div className='d-flex flex-column align-items-center'>
                                    <img src='/assets/emptyIcon.png' width={254} height={252} alt='No file found' />
                                    <h3 className='mt-3'><strong>No trashed files</strong></h3>
                                    <h6 className='text-muted mt-n2'>Please try again when there are trashed files</h6>
                                    <GoHome />
                                </div>
                            </Col> : <>
                                <Col md='12'>
                                    <div className="d-flex flex-row trashed-summary-container mb-4">
                                        <TrashIcon size={24} />
                                        <div className='ms-3'>
                                            <h6>
                                                <strong>{trash.data.length} total {pluralize('file', trash.data.length || 1)}</strong>
                                            </h6>
                                            <p className='fw-lighter text-muted mt-n2 small'>{trashSize}</p>
                                        </div>
                                        <div className="ms-auto">
                                            <button onClick={emptyTrash} className='btn btn-primary bg-white mx-2 btn-sm'>EMPTY TRASH</button>
                                            <button onClick={undoDelete} className='btn btn-primary bg-white btn-sm'>RECOVER FILES</button>
                                        </div>
                                    </div>
                                </Col>

                                {
                                    trash.data.map(file => (!query?.trim() || file.title.toLowerCase().includes(query.toLowerCase())) ? <Col md='3' key={file.id}>
                                        <FileCard file={file} />
                                    </Col> : null)
                                }
                            </>
                        }
                    </>
                }
            </Row>
        </>
    );
};

export default Trash