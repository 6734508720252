/* eslint-disable react-hooks/exhaustive-deps */
import './MyProfile.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { Lock, Pencil, Person } from 'react-bootstrap-icons';
import { changePassword, loginSuccess, updatePersonalInformationDetails, updateProfileColorDetails, updateUserAddressDetails } from '../../../auth/authSlice';
import { UserAddressInterface, UserInterface } from '../../../../interfaces/users.interface';
import { createImageFromInitials } from '../../../../shared/lib/createImageFromInitials';
import { Button, Col, Form, Offcanvas, Row } from 'react-bootstrap';
import { FormEvent, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

const MyProfile: React.FC = () => {

    const dispatch = useDispatch()
    const userDetails = useSelector((state: RootState) => state.auth.user) as UserInterface;
    const [addressForm, setAddressForm] = useState<{ address: UserAddressInterface; error: string; show: boolean; processing: boolean; }>({ address: { ...userDetails.address }, processing: false, error: '', show: false })
    const [PIForm, setPIForm] = useState<{ pi: { name: string; phone_number: string; }; error: string; show: boolean; processing: boolean; }>({ pi: { name: userDetails.name, phone_number: userDetails.phone_number }, processing: false, error: '', show: false })
    const [passwordForm, setPasswordForm] = useState<{ passwords: { old_p: string; new_p: string; r_new_p: string; }; error: string; show: boolean; processing: boolean; }>({ passwords: { old_p: '', new_p: '', r_new_p: '' }, processing: false, error: '', show: false })
    const [profileColor, setProfileColor] = useState(userDetails.profile_color)

    useEffect(() => {
        dispatch(loginSuccess({
            ...userDetails,
            profile_color: profileColor,
            profile_pic: createImageFromInitials(120, userDetails.name as string, profileColor) as string
        }));
        const delayDebounceFn = setTimeout(() => {
            updateProfileColorDetails(dispatch, userDetails, profileColor)
        }, 5000)
        return () => clearTimeout(delayDebounceFn)
    }, [profileColor])

    const handleAddressFormSubmission = async (e: FormEvent) => {
        e.preventDefault()
        setAddressForm({ ...addressForm, processing: true })
        try {
            await toast.promise(
                updateUserAddressDetails(dispatch, userDetails, addressForm.address),
                {
                    loading: 'Updating...',
                    success: <b>Address successfully updated!</b>,
                    error: <b>Error occurred during update!</b>,
                }
            );
            setAddressForm({ ...addressForm, processing: false, show: false })
            return
        } catch (error: any) {
            console.log(error)
            setAddressForm({ ...addressForm, processing: false, error: error?.message || error })
            return
        }
    };

    const handlePIFormSubmission = async (e: FormEvent) => {
        e.preventDefault()
        setPIForm({ ...PIForm, processing: true })
        try {
            await toast.promise(
                updatePersonalInformationDetails(dispatch, userDetails, PIForm.pi),
                {
                    loading: 'Updating...',
                    success: <b>Personal information successfully updated!</b>,
                    error: <b>Error occurred during update!</b>,
                }
            );
            setPIForm({ ...PIForm, processing: false, show: false })
            return
        } catch (error: any) {
            console.log(error)
            setPIForm({ ...PIForm, processing: false, error: error?.message || error })
            return
        }
    };

    const handlePasswordFormSubmission = async (e: FormEvent) => {
        e.preventDefault()
        if (passwordForm.passwords.r_new_p !== passwordForm.passwords.new_p) {
            setPasswordForm({ ...passwordForm, processing: false, error: 'Password fields do not match!' })
            return
        }
        setPasswordForm({ ...passwordForm, processing: true })
        try {
            await toast.promise(
                changePassword(passwordForm.passwords.old_p, passwordForm.passwords.new_p),
                {
                    loading: 'Changing password...',
                    success: <b>Password successfully updated!</b>,
                    error: <b>Error occurred during update!</b>,
                }
            );
            setPasswordForm({ ...passwordForm, passwords: { ...passwordForm.passwords, old_p: '', new_p: '', r_new_p: '' }, processing: false, show: false })
            return
        } catch (error: any) {
            // console.log(error)
            setPasswordForm({ ...passwordForm, processing: false, error: error?.message || error })
            return
        }
    };

    return (
        <>
            <h5 className='my-4 fw-bold'>My profile</h5>
            <div className='row'>
                <div className='col-md-12 mb-4'>
                    <div className='bg-white profile-card d-flex flex-row p-3'>
                        <img src={userDetails.profile_pic || createImageFromInitials(120, userDetails.name as string, userDetails.profile_color)} alt="Profile" />
                        <div className="d-flex w-100 px-3 flex-row profile-card-child">
                            <div>
                                <p className='mb-n1 text-truncate'><strong>{userDetails.name}</strong></p>
                                <div className='text-muted small fw-light text-truncate'>{userDetails.email}</div>
                            </div>
                            <div className="ms-auto">
                                <input type="color" className="form-control form-control-color" onChange={(e) => setProfileColor(e.target.value)} value={profileColor} title="Choose your color" />
                                {/* <input style={{ visibility: 'hidden', width: 0 }} type="file" />
                                Edit <Pencil className='ms-2' /> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-12 mb-4'>
                    <div className='bg-white profile-card p-4'>
                        <div className="d-flex flex-row mb-4">
                            <h6>
                                <strong>Personal Information</strong>
                            </h6>
                            <div className="ms-auto profile-edit-btn" onClick={() => setPIForm({ ...PIForm, show: true })}>Edit <Pencil className='ms-1' size={12} /></div>
                        </div>
                        <div className='row'>
                            <div className="col-md-12">
                                <small className='fw-lighter small'>Full Name</small>
                                <div><strong>{userDetails.name}</strong></div>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className="col-md-3">
                                <small className='fw-lighter small'>Email Address</small>
                                <div><strong>{userDetails.email}</strong></div>
                            </div>
                            <div className="col-md-3">
                                <small className='fw-lighter small'>Mobile</small>
                                <div><strong>{userDetails.phone_number || '-'}</strong></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-12 mb-4'>
                    <div className='bg-white profile-card p-4'>
                        <div className="d-flex flex-row mb-4">
                            <h6>
                                <strong>Address</strong>
                            </h6>
                            <div className="ms-auto profile-edit-btn" onClick={() => setAddressForm({ ...addressForm, show: true })}>Edit <Pencil className='ms-1' size={12} /></div>
                        </div>
                        <div className='row'>
                            <div className="col-md-3">
                                <small className='fw-lighter small'>Street</small>
                                <div><strong>{userDetails.address.street || '-'}</strong></div>
                            </div>
                            <div className="col-md-3">
                                <small className='fw-lighter small'>City/State</small>
                                <div><strong>{userDetails.address.city || '-'}</strong></div>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className="col-md-3">
                                <small className='fw-lighter small'>Post Code</small>
                                <div><strong>{userDetails.address.post_code || '-'}</strong></div>
                            </div>
                            <div className="col-md-3">
                                <small className='fw-lighter small'>Country</small>
                                <div><strong>{userDetails.address.country || '-'}</strong></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-12 mb-4'>
                    <div className='bg-white profile-card p-4'>
                        <div className="d-flex flex-row">
                            <h6 className='pt-1'>
                                <strong>Security</strong>
                            </h6>
                            <div className="ms-auto profile-edit-btn" onClick={() => setPasswordForm({ ...passwordForm, show: true })}>Change password <Lock className='ms-1' size={12} /></div>
                        </div>
                    </div>
                </div>
            </div>
            <Offcanvas show={addressForm.show} onHide={() => setAddressForm({ ...addressForm, show: false })} key={'updateAddressCanvas'} placement={'end'} name={'updateAddressCanvas'}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title><Person className='me-2' /> Update Address</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                        <Col md='12'>
                            <Form onSubmit={handleAddressFormSubmission}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Street</Form.Label>
                                    <Form.Control type="text" value={addressForm.address.street} onChange={(e) => setAddressForm({ ...addressForm, error: '', address: { ...addressForm.address, street: e.target.value } })} placeholder="Street" maxLength={120} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>City/State</Form.Label>
                                    <Form.Control type="text" value={addressForm.address.city} onChange={(e) => setAddressForm({ ...addressForm, error: '', address: { ...addressForm.address, city: e.target.value } })} placeholder="City/State" maxLength={120} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Post Code</Form.Label>
                                    <Form.Control type="text" value={addressForm.address.post_code} onChange={(e) => setAddressForm({ ...addressForm, error: '', address: { ...addressForm.address, post_code: e.target.value } })} placeholder="Post Code" maxLength={10} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control type="text" value={addressForm.address.country} onChange={(e) => setAddressForm({ ...addressForm, error: '', address: { ...addressForm.address, country: e.target.value } })} placeholder="Country" maxLength={120} />
                                </Form.Group>
                                {
                                    addressForm.error && <Form.Group className="mb-3">
                                        <Form.Text className="text-danger">
                                            {addressForm.error}
                                        </Form.Text>
                                    </Form.Group>
                                }
                                <Button variant="primary" className='text-white' disabled={addressForm.processing} type="submit">
                                    {
                                        addressForm.processing ? <div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div> :
                                            <><Pencil className='me-2' />
                                                <small>Update</small>
                                            </>
                                    }
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
            <Offcanvas show={PIForm.show} onHide={() => setPIForm({ ...PIForm, show: false })} key={'updatePersonalInformationCanvas'} placement={'end'} name={'updatePersonalInformationCanvas'}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title><Person className='me-2' /> Update Personal Information</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                        <Col md='12'>
                            <Form onSubmit={handlePIFormSubmission}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Full Name</Form.Label>
                                    <Form.Control type="text" required value={PIForm.pi.name} onChange={(e) => setPIForm({ ...PIForm, error: '', pi: { ...PIForm.pi, name: e.target.value } })} placeholder="Full Name" maxLength={120} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Mobile Phone</Form.Label>
                                    <Form.Control type="text" value={PIForm.pi.phone_number} onChange={(e) => setPIForm({ ...PIForm, error: '', pi: { ...PIForm.pi, phone_number: e.target.value } })} placeholder="Example: +447561234567" maxLength={30} />
                                </Form.Group>
                                {
                                    PIForm.error && <Form.Group className="mb-3">
                                        <Form.Text className="text-danger">
                                            {PIForm.error}
                                        </Form.Text>
                                    </Form.Group>
                                }
                                <Button variant="primary" className='text-white' disabled={PIForm.processing} type="submit">
                                    {
                                        PIForm.processing ? <div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div> :
                                            <><Pencil className='me-2' />
                                                <small>Update</small>
                                            </>
                                    }
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
            <Offcanvas show={passwordForm.show} onHide={() => setPasswordForm({ ...passwordForm, show: false })} key={'updatePasswordCanvas'} placement={'end'} name={'updatePasswordCanvas'}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title><Lock className='me-2' /> Change Password</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                        <Col md='12'>
                            <Form onSubmit={handlePasswordFormSubmission}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Current password</Form.Label>
                                    <Form.Control type="password" required value={passwordForm.passwords.old_p} onChange={(e) => setPasswordForm({ ...passwordForm, error: '', passwords: { ...passwordForm.passwords, old_p: e.target.value } })} placeholder="Current password" maxLength={120} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>New password</Form.Label>
                                    <Form.Control type="password" required value={passwordForm.passwords.new_p} onChange={(e) => setPasswordForm({ ...passwordForm, error: '', passwords: { ...passwordForm.passwords, new_p: e.target.value } })} placeholder="New password" maxLength={120} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Repeat new password</Form.Label>
                                    <Form.Control type="password" required value={passwordForm.passwords.r_new_p} onChange={(e) => setPasswordForm({ ...passwordForm, error: '', passwords: { ...passwordForm.passwords, r_new_p: e.target.value } })} placeholder="Repeat new password" maxLength={120} />
                                </Form.Group>

                                {
                                    passwordForm.error && <Form.Group className="mb-3">
                                        <Form.Text className="text-danger">
                                            {passwordForm.error}
                                        </Form.Text>
                                    </Form.Group>
                                }
                                <Button variant="primary" className='text-white' disabled={passwordForm.processing} type="submit">
                                    {
                                        passwordForm.processing ? <div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div> :
                                            <><Pencil className='me-2' />
                                                <small>Update</small>
                                            </>
                                    }
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default MyProfile