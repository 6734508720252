import toast from "react-hot-toast";
import { Axios } from "../http/Axios";
import { getUsers } from "./getUsers";
import { Dispatch } from "@reduxjs/toolkit";

interface NewUserInterface {
    name: string;
    email: string;
    is_admin: boolean;
    password: string;
}

export const createUser = async (dispatch: Dispatch, user: NewUserInterface) => {
    Axios.post(`users`, user)
        .then(() => {
            getUsers(dispatch)
        })
        .catch((error) => {
            console.error(error);
            toast.error(error?.message || 'Error occurred while creating user')
        });
};
