/* eslint-disable react-hooks/exhaustive-deps */
import './Admins.scss';
import { toast } from 'react-hot-toast';
import { BarLoader } from 'react-spinners';
import { RootState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { variables } from '../../../../shared/variables';
import { getUsers } from '../../../../shared/services/getUsers';
import AppNav from '../../../../shared/components/AppNav/AppNav';
import { deleteUser } from '../../../../shared/services/deleteUser';
import { modifyUser } from '../../../../shared/services/modifyUser';
import { createUser } from '../../../../shared/services/createUser';
import { UserInterface } from '../../../../interfaces/users.interface';
import { SearchPages } from '../../../../shared/enums/searchPages.enum';
import { Button, Col, Dropdown, Form, Offcanvas, Row } from 'react-bootstrap';
import { FormEvent, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { PersonAdd, PlusLg, ThreeDotsVertical, Trash, X } from 'react-bootstrap-icons';
import { createImageFromInitials } from '../../../../shared/lib/createImageFromInitials';
import ConfirmationModal from '../../../../shared/components/ConfirmationModal/ConfirmationModal';

interface NewUserState {
    name: string;
    email: string;
    error: string;
    is_admin: true;
    password: string;
    processing: boolean;
}


const Admins: React.FC = () => {

    const buttonRef = useRef<HTMLElement | null>(document.getElementById('new_person_add'));
    const dispatch = useDispatch()
    const authDetails = useSelector((state: RootState) => state.auth);
    const query = useSelector((state: RootState) => state.auth.searches.admin);
    const [stagedUserForUpdate, setStagedUserForUpdate] = useState<UserInterface | null>(null);
    const [stagedUserForDeletion, setStagedUserForDeletion] = useState<UserInterface | null>(null);
    const [showNewUserCanvas, setShowNewUserCanvas] = useState(false);
    const [userForm, setUserForm] = useState<NewUserState>({ email: '', is_admin: true, password: '', name: '', error: '', processing: false });

    const handleUsersFetch = useCallback(() => {
        getUsers(dispatch)
    }, []);

    useEffect(() => {
        // Fetch files
        handleUsersFetch()
    }, [])

    useEffect(() => {
        if (!buttonRef.current) {
            buttonRef.current = document.getElementById('new_person_add')
        }
        if (buttonRef.current) {
            buttonRef.current.addEventListener('click', () => setShowNewUserCanvas(true));
        }
        // Clean up the event listener when the component unmounts
        return () => {
            if (buttonRef.current) {
                buttonRef.current.removeEventListener('click', () => setShowNewUserCanvas(false));
            }
        };
    }, [buttonRef.current]);

    const deleteUserHandler = async () => {
        if (!stagedUserForDeletion) {
            setStagedUserForDeletion(null)
            return
        }
        try {
            await toast.promise(
                deleteUser(dispatch, stagedUserForDeletion),
                {
                    loading: 'Deleting...',
                    success: <b>{stagedUserForDeletion.name} successfully deleted!</b>,
                    error: <b>Error occurred during deletion!</b>,
                }
            );
        } catch (error) {
            console.log(error)
        }
        setStagedUserForDeletion(null)
    }

    const deleteUserPrivilegeHandler = async () => {
        if (!stagedUserForUpdate) {
            setStagedUserForUpdate(null)
            return
        }
        try {
            await toast.promise(
                modifyUser(dispatch, { ...stagedUserForUpdate, is_admin: false }),
                {
                    loading: 'Updating...',
                    success: <b>{stagedUserForUpdate.name} Admin privilege successfully revoked for {stagedUserForUpdate.name}!</b>,
                    error: <b>Error occurred during update!</b>,
                }
            );
        } catch (error) {
            console.log(error)
        }
        setStagedUserForUpdate(null)
    }

    const handleNewUserSubmission = async (e: FormEvent) => {
        e.preventDefault()
        if (authDetails?.users.find(user => user.email === userForm.email)) {
            setUserForm({
                ...userForm, error: `'${userForm.email}' already exists!`
            })
            return
        }
        setUserForm({
            ...userForm, processing: true
        })
        try {
            await toast.promise(
                createUser(dispatch, userForm),
                {
                    loading: 'Creating...',
                    success: <b>User successfully created!</b>,
                    error: <b>Error occurred during creation!</b>,
                }
            );
            setUserForm({
                ...userForm, password: '', email: '', name: '', error: '', processing: false
            })
            setShowNewUserCanvas(false)
        } catch (error: any) {
            setUserForm({
                ...userForm, error, processing: false
            })
        }
    }

    return (
        <>
            <AppNav placeholder='Search admins...' page={SearchPages.ADMINS} />
            {
                !authDetails?.users?.length ? <BarLoader
                    color={variables.primaryColor}
                    loading={true}
                    cssOverride={{
                        display: "block",
                        margin: "0 auto",
                        marginTop: '200px'
                    }}
                    width={200}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                /> : <>
                    <h5 className='my-4 fw-bold'>Admins</h5>
                    <div className='row'>
                        <div className="col-md-12">
                            <div className='app-table admins'>
                                <table className='w-100'>
                                    <thead>
                                        <tr>
                                            <th style={{ 'width': '60px' }}></th>
                                            <th style={{ 'width': '45%' }}>Name</th>
                                            <th>Email</th>
                                            <th>Mobile</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            authDetails.users.filter(user => user.is_admin && (!query?.trim() || user.name.toLowerCase().includes(query.toLowerCase()))).map(user => <tr key={user.sub}>
                                                <td className='px-1'>
                                                    <img src={user?.profile_pic || createImageFromInitials(45, user?.name as string, '#EEFFF7')} alt="Profile" />
                                                </td>
                                                <td className='mouse-pointer'>
                                                    <strong>{user.name}</strong>
                                                </td>
                                                <td><span className='fw-light small'>{user.email}</span></td>
                                                <td><span className='fw-light small'>{user.phone_number || '-'}</span></td>
                                                <td className='text-end pe-3'>
                                                    {
                                                        user.sub !== authDetails.user?.sub ?
                                                            <Dropdown className="d-inline custom-dropdown">
                                                                <Dropdown.Toggle className="custom-dropdown-toggle">
                                                                    <ThreeDotsVertical className='text-muted mouse-pointer' size={18} />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item className="custom-dropdown-item small" onClick={() => setStagedUserForUpdate(user)}><X className='text-danger me-2' /> Revoke admin privilege</Dropdown.Item>
                                                                    <Dropdown.Item className="custom-dropdown-item small" onClick={() => setStagedUserForDeletion(user)}><Trash className='text-danger me-2' /> Delete user</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                            : null
                                                    }
                                                </td>
                                            </tr>)
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <ConfirmationModal
                        show={!!stagedUserForUpdate}
                        message="Are you sure you want to revoke admin privilege for this user?"
                        onConfirm={deleteUserPrivilegeHandler}
                        onCancel={() => setStagedUserForUpdate(null)}
                    />
                    <ConfirmationModal
                        show={!!stagedUserForDeletion}
                        message="Are you sure you want to delete this user?"
                        onConfirm={deleteUserHandler}
                        onCancel={() => setStagedUserForDeletion(null)}
                    />
                    <Offcanvas show={showNewUserCanvas} onHide={() => setShowNewUserCanvas(false)} key={'newUserCanvas'} placement={'end'} name={'newUserCanvas'}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title><PersonAdd className='me-2' /> New Admin</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Row>
                                <Col md='12'>
                                    <Form onSubmit={handleNewUserSubmission}>
                                        <Form.Group className="mb-3" controlId="formNewUserName">
                                            <Form.Label>Full name<i className='text-danger'>*</i></Form.Label>
                                            <Form.Control type="text" value={userForm.name} onChange={(e) => setUserForm({ ...userForm, error: '', name: e.target.value })} placeholder="Full name" required maxLength={60} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formNewUserEmail">
                                            <Form.Label>Email<i className='text-danger'>*</i></Form.Label>
                                            <Form.Control type="email" value={userForm.email} onChange={(e) => setUserForm({ ...userForm, error: '', email: e.target.value })} placeholder="Email" required />
                                        </Form.Group>
                                        <Form.Group className="mb-4" controlId="formNewUserPassword">
                                            <Form.Label>Temp. Password<i className='text-danger'>*</i></Form.Label>
                                            <Form.Control type="password" value={userForm.password} onChange={(e) => setUserForm({ ...userForm, error: '', password: e.target.value })} placeholder="Temporary password" required maxLength={60} />
                                            {
                                                userForm.error && <Form.Text className="text-danger">
                                                    {userForm.error}
                                                </Form.Text>
                                            }
                                        </Form.Group>
                                        <Button variant="primary" className='text-white' disabled={userForm.processing} type="submit">
                                            {
                                                userForm.processing ? <div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div> :
                                                    <Fragment>
                                                        <PlusLg className='me-2' />
                                                        <small>Create</small>
                                                    </Fragment>

                                            }
                                        </Button>
                                    </Form>
                                </Col>
                            </Row>
                        </Offcanvas.Body>
                    </Offcanvas>
                </>
            }
        </>
    );
};

export default Admins