import { Axios } from "../http/Axios";
import { Dispatch } from "@reduxjs/toolkit";
import { refreshSessionData } from "./refreshSessionData";

export const deleteFolder = async (dispatch: Dispatch, id: string): Promise<true> => {
    const fallback_error_message = 'Error occurred while deleting folder';
    return new Promise((resolve, reject) => {
        Axios.delete(`admins/folders/${id}`)
            .then(async () => {
                try {
                    await refreshSessionData(dispatch)
                    resolve(true)
                } catch (error: any) {
                    reject(error?.message || fallback_error_message)
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error?.message || fallback_error_message)
            });
    })

};