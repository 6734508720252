import { Axios } from "../http/Axios";
import { Dispatch } from "@reduxjs/toolkit";
import { logout, setSession } from "../../pages/auth/authSlice";
import { SessionInfoResponse } from "../../interfaces/sessionInfoResponse.interface";

export const refreshSessionData = async (dispatch: Dispatch) => {
    Axios.get('session')
        .then((response: { data: { data: SessionInfoResponse } }) => {
            dispatch(setSession(response.data.data))
        })
        .catch((error) => {
            console.error(error);
            dispatch(logout())
        });
};