import './MP3Player.scss';
import { useRef, useState } from 'react';
import { Pause, Play } from 'react-bootstrap-icons';

interface MP3PlayerProps {
    url: string;
}
const MP3Player: React.FC<MP3PlayerProps> = (props: MP3PlayerProps) => {
    const { url } = props

    const audioRef = useRef<HTMLAudioElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const togglePlay = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };
    return (
        <div className="mp3-player">
            <audio ref={audioRef} src={url} />
            <div className="audio-controls">
                {isPlaying ? <button onClick={togglePlay}><Pause size={26} className='mx-2' /> Pause</button> : <button onClick={togglePlay}><Play size={26} className='mx-2' /> Play</button>}
            </div>
        </div>
    );
};

export default MP3Player