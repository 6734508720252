import toast from 'react-hot-toast';
import React, { useState } from 'react';
import logo from '../../../../logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { registerUser } from '../../authSlice';
import { ArrowRight } from 'react-bootstrap-icons';
import { isEmail } from '../../../../shared/lib/isEmail';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { UserInterface } from '../../../../interfaces/users.interface';

const Register: React.FC = () => {

    const navigate = useNavigate()
    const [passwords, setPasswords] = useState({ p: '', rp: '' });
    const [processTrack, setProcessTrack] = useState({ message: '', processing: false });
    const [ud, setUd] = useState<UserInterface>({
        sub: '',
        name: '',
        email: '',
        id_token: '',
        is_admin: false,
        profile_pic: '',
        phone_number: '',
        access_token: '',
        refresh_token: '',
        email_verified: false,
        profile_color: '#ffffff',
        address: {
            city: '',
            street: '',
            country: '',
            post_code: '',
        }
    });

    const handleRegistration = async (e: React.FormEvent) => {
        e.preventDefault();
        setProcessTrack({ ...processTrack, message: '' });

        if (!passwords.p.trim() || !passwords.rp.trim()) {
            setProcessTrack({ ...processTrack, message: 'Please fill all password fields.' });
            return;
        }

        if (passwords.p !== passwords.rp) {
            setProcessTrack({ ...processTrack, message: 'Password fields do not match.' });
            return;
        }

        if (!isEmail(ud.email)) {
            setProcessTrack({ ...processTrack, message: 'Please enter a valid email.' });
            return;
        }
        setProcessTrack({ ...processTrack, processing: true });

        const result = await registerUser(ud, passwords.p);
        if (result.success) {
            setProcessTrack({ ...processTrack, processing: false, message: '' });
            toast.success('Registration successful. Please check your email to verify the account.', { duration: 1000 * 5 })
            setUd({ ...ud, email: '' })
            setPasswords({ ...passwords, p: '', rp: '' })
            navigate('/login')
        } else {
            setProcessTrack({ ...processTrack, processing: false, message: `Registration failed: ${result.error}` });
        }
    };

    return (<div className='container'>
        <div className='login-content'>
            <img src={logo} className='logo' alt="Logo" />
            <Form onSubmit={handleRegistration}>
                <Row className="align-items-center mt-3">
                    <Col md={12} className="my-3">
                        <Form.Control className='bg-white' required type='text' readOnly={processTrack.processing} value={ud.name} onChange={(e) => { setUd({ ...ud, name: e.target.value }); setProcessTrack({ ...processTrack, message: '' }) }} placeholder="Full Name" />
                    </Col>

                    <Col md={12} className="my-3">
                        <Form.Control className='bg-white' required type='email' readOnly={processTrack.processing} value={ud.email} onChange={(e) => { setUd({ ...ud, email: e.target.value }); setProcessTrack({ ...processTrack, message: '' }) }} placeholder="Email" />
                    </Col>

                    <Col md={12} className="my-3">
                        <Form.Control className='bg-white' required type='password' readOnly={processTrack.processing} value={passwords.p} onChange={(e) => { setPasswords({ ...passwords, p: e.target.value }); setProcessTrack({ ...processTrack, message: '' }) }} placeholder="Password" />
                    </Col>

                    <Col md={12} className="my-3">
                        <Form.Control className='bg-white' required type='password' readOnly={processTrack.processing} value={passwords.rp} onChange={(e) => { setPasswords({ ...passwords, rp: e.target.value }); setProcessTrack({ ...processTrack, message: '' }) }} placeholder="Repeat Password" />
                    </Col>
                </Row>

                {processTrack.message ? <Alert variant='danger'>
                    <small>{processTrack.message}</small>
                </Alert> : null}
                <Button className='btn btn-primary px-3 text-white' type='submit' disabled={processTrack.processing || !isEmail(ud.email)}>
                    {
                        processTrack.processing ? <div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div> : <>Register<ArrowRight color="white" className='ms-2 pb-1' size={18} /></>
                    }
                </Button>
            </Form>
            <div className='py-2'>
                <Link to='/login' className='small'>
                    <u>Login</u>
                </Link>
            </div>
        </div>
    </div>
    );
};

export default Register;
