import './AppNav.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import SearchBar from '../SearchBar/SearchBar';
import { SearchPages } from '../../enums/searchPages.enum';
import { createImageFromInitials } from '../../lib/createImageFromInitials';

interface AppNavProp {
    page: SearchPages;
    placeholder: string;
    searchDisabled?: boolean;
}

const AppNav: React.FC<AppNavProp> = (prop: AppNavProp) => {
    const userDetails = useSelector((state: RootState) => state.auth.user);
    return (
        <div className="app-nav d-flex">
            <div className='me-auto w-100'>
                <SearchBar {...prop} />
            </div>
            <a href="/settings/my-profile">
                <div className="profile-pic mt-2">
                    <img src={userDetails?.profile_pic || createImageFromInitials(50, userDetails?.name as string, userDetails?.profile_color)} alt="Profile" />
                </div>
            </a>
            <a href="/settings/my-profile">
                <div className="profile-name mt-2">{userDetails?.name}</div>
            </a>
        </div>
    );
};

export default AppNav