import { Axios } from "../http/Axios";
import { Paginated } from "../../interfaces/paginated.interface";
import { FilesResponseInterface } from "../../interfaces/filesResponse.interface";

export const getFile = async (folder_id: string, file_id: string): Promise<FilesResponseInterface | null> => {
    return new Promise((resolve, reject) => {
        Axios.get(`users/folders/${folder_id}/files/${file_id}`)
            .then((response: { data: { data: Paginated<FilesResponseInterface> } }) => {
                resolve(response.data.data.data.length ? response.data.data.data[0] : null)
            })
            .catch((error) => {
                console.error(error);
                reject(error?.message || 'Error occurred while fetching file')
            });
    })
};
