import { Pagination } from 'react-bootstrap';
interface PaginatorProp {
    page: number;
    total: number;
    current: number;
    totalPages: number;
    next_page: Function;
}

const Paginator: React.FC<PaginatorProp> = (prop: PaginatorProp) => {

    const getPageRange = (): number[] => {
        let max = 5
        let response: number[] = []
        if (prop.page <= 3) {
            for (let index = 1; index <= max && index <= prop.totalPages; index++) {
                response.push(index)
            }
            return response
        }
        for (let index = prop.page > 2 ? prop.page - 2 : prop.page; index <= prop.totalPages && response.length <= max; index++) {
            response.push(index)
        }
        return response
    }

    return (
        <Pagination>
            {
                getPageRange().map(value => {
                    return <Pagination.Item key={value} onClick={() => value === prop.current ? null : prop.next_page(value)} active={value === prop.current}>
                        {value}
                    </Pagination.Item>
                })
            }
        </Pagination>
    );
};
export default Paginator