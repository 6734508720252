import { ArrowUpCircle } from 'react-bootstrap-icons';
import './ScrollToTop.scss';
import { useState, useEffect } from 'react';

function ScrollToTop() {

    const [isVisible, setIsVisible] = useState(false);
    const handleScroll = () => setIsVisible(window.scrollY > 1000);
    const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className={`scroll-to-top-button ${isVisible ? 'visible' : ''}`}>
            <button className='btn btn-primary text-white py-2 shadow' onClick={scrollToTop}><ArrowUpCircle size={24} /></button>
        </div>
    );
}

export default ScrollToTop;
