/* eslint-disable react-hooks/exhaustive-deps */
import './SearchBar.scss';
import { Spinner } from 'react-bootstrap';
import { RootState } from '../../../store';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SearchPages } from '../../enums/searchPages.enum';
import { setTrashSearchParams } from '../../../pages/trash/trashSlice';
import { FolderFill, Search as SearchIcon } from 'react-bootstrap-icons';
import { setStarredSearchParams } from '../../../pages/FolderContents/folderContentsSlice';
import { FilesResponseInterface } from '../../../interfaces/filesResponse.interface';
import { setAdminSearchParams, setFoldersSearchParams, setMembersSearchParams } from '../../../pages/auth/authSlice';

interface SearchBarProp {
    query?: string;
    page: SearchPages;
    placeholder?: string;
    searchDisabled?: boolean;
}

const SearchBar: React.FC<SearchBarProp> = (prop: SearchBarProp) => {

    const { page } = prop;
    const { slug } = useParams();

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [query, setQuery] = useState(prop.query || '');
    const folders = useSelector((state: RootState) => state.auth.session?.folders) || [];
    const folder = folders.find(folder => folder.slug === slug);
    const isSearching = () => page === SearchPages.FILES && !folder?.files;
    const [fileSearchResults, setFileSearchResults] = useState<Array<FilesResponseInterface>>([]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {

            if (page === SearchPages.FILES && folder?.files) {
                // eslint-disable-next-line no-useless-escape
                const removePunctuation = (text: string): string => text.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');
                const searchFiles = (arr: Array<FilesResponseInterface>, search = ''): Array<FilesResponseInterface> => {
                    if (!search?.trim()) return arr
                    const searchIgnoringPunctuation = (sentence: string, text: string): boolean => removePunctuation(text.toLowerCase()).includes(removePunctuation(sentence.toLowerCase()));
                    return arr.filter(r => searchIgnoringPunctuation(search, r.index ? `${r.index} ${r.title}` : r.title));
                }
                setFileSearchResults(searchFiles(folder.files, query))
            }
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [query])

    const gotoLink = (link: string) => {
        if (page === SearchPages.FILES) {
            setQuery('')
            navigate(link)
        }
    }

    const updateQuery = (q: string) => {
        setQuery(q)
        if (page === SearchPages.STARRED) {
            dispatch(setStarredSearchParams(q))
        }
        if (page === SearchPages.TRASH) {
            dispatch(setTrashSearchParams(q))
        }
        if (page === SearchPages.ADMINS) {
            dispatch(setAdminSearchParams(q))
        }
        if (page === SearchPages.MEMBERS) {
            dispatch(setMembersSearchParams(q))
        }
        if (page === SearchPages.FOLDERS) {
            dispatch(setFoldersSearchParams(q))
        }
    }

    return (
        <>
            <div className={`search-wrapper ${prop.searchDisabled ? 'search-disabled' : ''}`}>
                <div className='d-flex justify-content-start search-parent'>
                    <div className='search-icon'>
                        {
                            isSearching() ? <Spinner animation="grow" size='sm' /> : <SearchIcon size={18} />
                        }
                    </div>
                    <div className={`search-input`}><input value={query} readOnly={isSearching()} autoComplete='off' onChange={(e) => updateQuery(e.target.value)} placeholder={prop.placeholder || 'Search files...'} type="text" /></div>
                </div>

                {
                    page === SearchPages.FILES && folder && query?.trim() ? <>
                        {
                            fileSearchResults?.length ? <div className="search-result list-group">
                                {
                                    fileSearchResults.map((result) => <Fragment key={result.id}>
                                        <li onClick={() => gotoLink(`/folders/${folder.slug}/${result.id}`)} className={'list-group-item list-group-item-action mouse-pointer'} aria-current="true">
                                            <div className="d-flex w-100 justify-content-between">
                                                <h6 className="mb-1"><b>{result.title}</b></h6>
                                                <small>{result.index}</small>
                                            </div>
                                            <small><FolderFill className='search-mini-icon me-1' /> {folder.title}</small>
                                        </li>
                                    </Fragment>)
                                }
                            </div> : <div className="search-result bg-white p-2"><i><small className='text-muted'>No record found!</small></i></div>
                        }
                    </> : null
                }
            </div>
        </>
    );
};

export default SearchBar