import { Axios } from "../http/Axios";
import { Dispatch } from "@reduxjs/toolkit";
import { FilesResponseInterface } from "../../interfaces/filesResponse.interface";
import { getTrashFilesFailure, getTrashFilesSuccess } from "../../pages/trash/trashSlice";

export const getTrashedFiles = async (dispatch: Dispatch): Promise<true> => {
    const fallback_error_message = 'Error occurred while getting trashed files';
    return new Promise((resolve, reject) => {
        Axios.get(`admins/recycle-bin`)
            .then((response: { data: { data: Array<FilesResponseInterface> } }) => {
                dispatch(getTrashFilesSuccess(response.data.data))
                resolve(true)
            })
            .catch((error) => {
                console.error(error);
                dispatch(getTrashFilesFailure(error?.message || fallback_error_message))
                reject(error?.message || fallback_error_message)
            });
    })
};