import axios from "axios";
import { FileTypes } from "../enums/fileTypes.enum";
import { getUploadURL } from "../services/getUploadURL";
import { PresignedPolicy } from "../../interfaces/presignedPolicy.interface";
import moment from "moment";

let presigned_policies: PresignedPolicy;
export async function directUpload(file: File, folder_id: string): Promise<string> {
    //Initiate upload
    let result = presigned_policies;
    if (!result) {
        result = await getUploadURL(folder_id)
        presigned_policies = result
    }

    let policy_decoded = JSON.parse(atob(result.fields.Policy)) as { conditions: Array<{ acl: string }> }
    let formData = new FormData()
    formData.append('key', result.fields.key);
    let acl = policy_decoded.conditions.find(c => c.acl)
    if (acl) formData.append('acl', acl.acl);
    let content_type = '';
    switch (((file.name as string).split(".").pop() as string).toLowerCase()) {
        case FileTypes.MP4:
            content_type = "video/mp4"
            break;
        case FileTypes.MP3:
            content_type = "audio/mpeg"
            break;
        case FileTypes.PDF:
            content_type = "application/pdf"
            break;
    }
    formData.append('Content-Type', content_type);
    formData.append('Policy', result.fields.Policy);
    formData.append('X-Amz-Date', result.fields['X-Amz-Date']);
    formData.append('X-Amz-Signature', result.fields['X-Amz-Signature']);
    formData.append('X-Amz-Algorithm', result.fields['X-Amz-Algorithm']);
    formData.append('X-Amz-Credential', result.fields['X-Amz-Credential']);
    formData.append('X-Amz-Security-Token', result.fields['X-Amz-Security-Token']);
    formData.append('bucket', result.fields.bucket);
    let file_name = moment().unix() + file.name.split(" ").join("_");
    [" ", ")", "(", "#", "'", "/", "+"].map(character => file_name = file_name.split(character).join("_"))
    formData.append('file', file, file_name)
    await axios.post(`${result.url}/`, formData)
    // eslint-disable-next-line no-template-curly-in-string
    return result.fields.key.replace('${filename}', file_name)
}