/* eslint-disable react-hooks/exhaustive-deps */
import AppNav from '../AppNav/AppNav';
import ReactPlayer from 'react-player';
import { RootState } from '../../../store';
import { BarLoader } from 'react-spinners';
import { variables } from '../../variables';
import PDFViewer from '../PDFViewer/PDFViewer';
import MP3Player from '../MP3Player/MP3Player';
import { getFile } from '../../services/getFile';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { Breadcrumb, Pagination } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { SearchPages } from '../../enums/searchPages.enum';
import { setOpenedFile } from '../../services/setOpenedFile';
import { getPrevNextFile } from '../../services/getPrevNextFile';
import { CaretLeftFill, CaretRightFill } from 'react-bootstrap-icons';
import { FolderInterface } from '../../../interfaces/folder.interface';
import { FilesResponseInterface } from '../../../interfaces/filesResponse.interface';
import { SessionInfoResponse } from '../../../interfaces/sessionInfoResponse.interface';

const File: React.FC = () => {
    const { slug, file_id } = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [file, setFile] = useState<FilesResponseInterface>();
    const [navigatingPrevNext, setNavigatingPrevNext] = useState(false);
    const session = useSelector((state: RootState) => state.auth.session) as SessionInfoResponse;
    let folder = session?.folders?.find(folder => folder.slug === slug) as FolderInterface;

    const handlePageChange = useCallback(async () => {
        const file_finder = await getFile(folder.id, file_id as string)
        if (!file_finder || file_finder.deleted_at) {
            return navigate('/404')
        }
        setOpenedFile(dispatch, file_finder.id)
        setFile(file_finder)
    }, [slug, file_id]);

    useEffect(() => {
        folder = session?.folders?.find(folder => folder.slug === slug) as FolderInterface;
        if (!folder) navigate('/404')
        // Fetch file
        handlePageChange()
    }, [slug, file_id])

    const nextFile = async (forward = true) => {
        if (navigatingPrevNext) return
        setNavigatingPrevNext(true)
        try {
            const file_finder = await getPrevNextFile(file_id as string, forward ? 'next' : 'prev')
            navigate(`/folders/${slug}/${file_finder.id}`)
            setNavigatingPrevNext(false)
        } catch (error) {
            navigate('/404')
        }
    }

    return (
        <>
            <AppNav placeholder='Search files...' page={SearchPages.FILES} />
            {
                !file || !folder ? <BarLoader
                    color={variables.primaryColor}
                    loading={true}
                    cssOverride={{
                        display: "block",
                        margin: "0 auto",
                        marginTop: '200px'
                    }}
                    width={200}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                /> : <>

                    <div className="mt-4 d-flex justify-space-between">
                        <Breadcrumb className='fw-bold'>
                            <Breadcrumb.Item href={`/folders/${folder.slug}`}>
                                <h5 className='fw-bold'>{folder.title}</h5>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item className='fw-lighter' active>{file.title}</Breadcrumb.Item>
                        </Breadcrumb>

                        <Pagination className={'ms-auto mt-n2 not-in-mobile' + (navigatingPrevNext ? 'div-disabled' : '')}>
                            <Pagination.Item onClick={() => nextFile(false)}>
                                <CaretLeftFill /> Prev song
                            </Pagination.Item>
                            <Pagination.Item onClick={() => nextFile()}>
                                Next song
                                <CaretRightFill className='ms-1' />
                            </Pagination.Item>
                        </Pagination>
                    </div>

                    <div className='w-100'>
                        {
                            file.ext.toLowerCase() === 'mp4' && <div className='player-wrapper'>
                                <ReactPlayer
                                    className='my-3'
                                    url={file.url}
                                    width='100%'
                                    height='100%'
                                    controls={true}
                                />
                            </div>
                        }
                        {
                            file.ext.toLowerCase() === 'pdf' && <PDFViewer url={file.url} />
                        }
                        {
                            file.ext.toLowerCase() === 'mp3' && <MP3Player url={file.url} />
                        }
                    </div>
                </>
            }
        </>
    );
};

export default File