import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Paginated } from '../../interfaces/paginated.interface';
import { FileRequestFilter } from '../../interfaces/fileRequest.interface';
import { FilesResponseInterface } from '../../interfaces/filesResponse.interface';

interface FilesState {
    request: {
        error: string;
        folder_id: string;
        param: FileRequestFilter;
        data: Paginated<FilesResponseInterface> | null;
    };
    starred_search: {
        param: string;
    };
}

const initialState: FilesState = {
    request: {
        error: '',
        data: null,
        folder_id: '',
        param: {
            page: 1,
            limit: 30,
            search: '',
            sort: 'asc',
            sort_by: 'title',
        }
    },
    starred_search: {
        param: ''
    }
};

const folderContentsSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        replaceFile: (state, action: PayloadAction<FilesResponseInterface>) => {
            state.request.error = '';
            if (state.request.data) {
                const _index = state.request.data.data.findIndex(d => d.id === action.payload.id)
                if (_index !== -1) state.request.data.data[_index] = action.payload
            }
        },
        setStarredSearchParams: (state, action: PayloadAction<string>) => {
            state.starred_search.param = action.payload
        },
        setFilesRequestParams: (state, action: PayloadAction<FileRequestFilter>) => {
            state.request.param = action.payload
        },
        getFilesSuccess: (state, action: PayloadAction<{ data: Paginated<FilesResponseInterface>; folder_id: string; }>) => {
            state.request.error = '';
            state.request.data = action.payload.data
            state.request.folder_id = action.payload.folder_id
            state.request.param = {
                ...state.request.param,
                limit: action.payload.data.perPage,
                page: action.payload.data.currentPage,
            }
        },
        getFilesFailure: (state, action: PayloadAction<string>) => {
            state.request.data = null;
            state.request.error = action.payload;
        },
    },
});

export const {
    replaceFile,
    getFilesSuccess,
    getFilesFailure,
    setFilesRequestParams,
    setStarredSearchParams
} = folderContentsSlice.actions;
export const filesReducer = folderContentsSlice.reducer;
