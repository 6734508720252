import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilesResponseInterface } from '../../interfaces/filesResponse.interface';

interface TrashState {
    request: {
        error: string;
        data: Array<FilesResponseInterface> | null;
    };
    trash_search: {
        param: string;
    };
}

const initialState: TrashState = {
    request: {
        error: '',
        data: null,
    },
    trash_search: {
        param: ''
    }
};

const trashSlice = createSlice({
    name: 'trash',
    initialState,
    reducers: {
        setTrashSearchParams: (state, action: PayloadAction<string>) => {
            state.trash_search.param = action.payload
        },
        getTrashFilesSuccess: (state, action: PayloadAction<Array<FilesResponseInterface>>) => {
            state.request.error = '';
            state.request.data = action.payload
        },
        getTrashFilesFailure: (state, action: PayloadAction<string>) => {
            state.request.data = null;
            state.request.error = action.payload;
        },
    },
});

export const {
    getTrashFilesFailure,
    getTrashFilesSuccess,
    setTrashSearchParams
} = trashSlice.actions;
export const trashReducer = trashSlice.reducer;
