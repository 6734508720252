import { RootState } from './store';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import File from './shared/components/File/File';
import Trash from './pages/trash/components/Trash';
import Login from './pages/auth/components/Login/Login';
import Starred from './pages/starred/components/Starred';
import Admins from './pages/settings/components/admins/Admins';
import SideMenu from './shared/components/SideMenu/SideMenu';
import Folder from './pages/settings/components/folder/Folder';
import Members from './pages/settings/components/members/Members';
import Dashboard from './pages/dashboard/components/dashboard';
import Register from './pages/auth/components/Register/Register';
import Folders from './pages/settings/components/folders/Folders';
import MyProfile from './pages/settings/components/myProfile/MyProfile';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import FolderContents from './pages/FolderContents/components/FolderContents';

const App: React.FC = () => {
    const user = useSelector((state: RootState) => state.auth.user);
    return (
        <Fragment>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Router>
                <Routes>
                    <Route path="/403" element={<h1>Access Denied</h1>} />
                    <Route path="/404" element={<h1>Page Not Found</h1>} />
                    <Route path="/login" element={!user ? <Login /> : <Navigate to="/" />} />
                    <Route path="/register" element={!user ? <Register /> : <Navigate to="/" />} />
                    {!!user ? (
                        <>
                            <Route element={<SideMenu />}>
                                <Route path="/" element={<Dashboard />} />
                                <Route path="/trash" element={<Trash />} />
                                <Route path="/starred" element={<Starred />} />
                                <Route path="folders">
                                    <Route path=":slug">
                                        <Route path="" element={<FolderContents />} />
                                        <Route path=":file_id" element={<File />} />
                                    </Route>
                                </Route>
                            </Route>
                            <Route path='/settings' element={<SideMenu />}>
                                <Route path="my-profile" element={<MyProfile />} />
                                <Route path="folders">
                                    <Route path="" element={user?.is_admin ? <Folders /> : <Navigate to="/403" />} />
                                    <Route path=":slug" element={user?.is_admin ? <Folder /> : <Navigate to="/403" />} />
                                </Route>
                            </Route>
                            <Route path='/users' element={<SideMenu />}>
                                <Route path="admins" element={<Admins />} />
                                <Route path="members" element={<Members />} />
                            </Route>
                        </>
                    ) : (
                        <Route path="*" element={<Navigate to="/login" />} />
                    )}
                    <Route path="/*" element={<Navigate to="/404" />} />
                </Routes>
            </Router>
        </Fragment>
    );
};

export default App;
